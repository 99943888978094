import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdhocService } from 'src/app/shared/services/adhoc.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-satisfaction-survey',
  templateUrl: './satisfaction-survey.component.html',
  styleUrls: ['./satisfaction-survey.component.scss']
})
export class SatisfactionSurveyComponent implements OnInit {

  isLoading = false;
  showError = false;

  eventId: string = null;
  questionnaireId: string = '287DCF35-7B57-451F-A958-6138ADBF01F6'; //Req

  constructor(private globalData: GlobalDataService, private activatedRoute: ActivatedRoute, private router: Router, private adhoc: AdhocService) {
    globalData.updateBackRoute(null);
    activatedRoute.paramMap.subscribe((params) => {
      this.eventId = params.get('id');
      let questionnaireId = params.get('questionnaireId');
      if (questionnaireId) {
        this.questionnaireId = questionnaireId;
      }
    });
  }
  onSaved(): void {
    this.router.navigate(['./survey-completed']);
  }

  back(): void {
    this.router.navigate(['/wellness-event/corporate/consultation']);
  }

  ngOnInit(): void {
    /* console.log('OnInit') */;
    this.isLoading = true;
    if (this.eventId) {
      this.adhoc.getWCG(this.eventId).subscribe(r => {
        if (r) {
          this.router.navigate(['./survey-completed']);
        }
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
    }
  }

}
