<div class="container">
  <article class="grid">
    <ng-container *ngIf="isLoading === true">
      <span class="blank-line blank-line-4 d-block"></span>
      <three-bar-loader></three-bar-loader>
    </ng-container>
    <h1 style="margin-left: 6%;" class="mdc-typography--headline2">Client Satisfaction Survey</h1>
    <app-corporate-wellness-info-header style="width: 80%"></app-corporate-wellness-info-header>

    <div class="grid__inner grid__inner--layout-b8b" *ngIf="isLoading ===false">
      <div class="grid__cell grid__cell--align-center grid__cell--span-all">
        <div class="grid__cell grid__cell--align-center grid__cell--span-all" *ngIf="questionnaireId">
          <app-q [consultationId]="null" [questionnaireId]="questionnaireId" [reviewId]="eventId" [testRequested]="null"
            [consultationSelection]="'Survey'" [showBackButton]="false" [allowNotes]="false" [allowSubmit]="true"
            [forcedExaminationStatus]="0" [hideExaminationStatus]="true" (onSaved)="onSaved()" (onBackPressed)="null">
          </app-q>
          <!--  <app-q
          [consultationId]="null"
          [questionnaireId]="questionnaireId"
          [reviewId]="null"
          [testRequested]="null"
          [consultationSelection]="'Questionnaire'"
          [showBackButton]="false"
          [allowSubmit]="false"
          [forcedExaminationStatus]="0"
          (onSaved)="null"
          (onBackPressed)="null">
        </app-q>-->
        </div>
      </div>
    </div>

    <div class="mdc-btn-container">
      <div class="grid__cell text--align-center">
        <a class="motion-button--cancel" tabindex="0" title="Return to previous page" (click)="back()">Back</a>
      </div>
    </div>
  </article>
</div>