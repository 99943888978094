import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RISK_RATING } from '../../shared/enums';
import { ArrayFunctions } from '../array-functions';
import { BMICalculationParams } from '../interfaces/calculationMapper';
import { AbnormalityObject, HierarchicalQuestionObject, ListItemObject } from '../interfaces/questionnaireMapper';
import { ResponseItemObject } from '../interfaces/responseMapper';
import { GlobalDataService } from './global-data.service';

//TODO: Is Token neede here?
// import { KeycloakService } from 'keycloak-angular';






@Injectable({
  providedIn: 'root'
})
export class CalculationService {
  httpOptions: any = {};
  constructor(public http: HttpClient,
    private globalServ: GlobalDataService,
    // protected readonly keycloack: KeycloakService
  ) {

  }

  eapSurveyResult: string;

  adhocVCResult(normalId: string, abnormalId: string, unprotectedSexId: string, innebriatedSexId: string, adhocYesId: string, responseItems: ResponseItemObject[]): string {
    let result = normalId.toLowerCase();
    let unprotectedSex = false;
    let innebriatedSex = false;
    responseItems.forEach(response => {
      let q = response.question;
      if (unprotectedSexId.includes(response.questionId.toLowerCase())) {
        this.setRiskRating(q);
        if (adhocYesId.includes(response.listItemId.toLowerCase())) {
          unprotectedSex = true;
          q.atRisk = true;
        }
      }
      if (innebriatedSexId.includes(response.questionId.toLowerCase())) {
        this.setRiskRating(q);
        if (adhocYesId.includes(response.listItemId.toLowerCase())) {
          innebriatedSex = true;
          q.atRisk = true;
        }
      }
    });

    if (unprotectedSex || innebriatedSex) {
      result = abnormalId.toLowerCase();
    }
    return result;
  }


  // Calculate BMI from height and weight
  bmiCalculation(
    heightId: string,
    weightId: string,
    responses: ResponseItemObject[]
  ): number {
    let weightQuestion = responses.find(r => r.questionId == weightId),
      weight = weightQuestion && Number(weightQuestion.value) ? weightQuestion.value : NaN,
      heightQuestion = responses.find(r => r.questionId == heightId),
      height = heightQuestion && Number(heightQuestion.value) ? (heightQuestion.value) / 100 : NaN,
      result = (Number.isNaN(weight) || Number.isNaN(height)) ? 0 : Math.round((weight / (height * height)) * 10) / 10;


    //result = (weight == NaN || height == NaN) ? 0 : Math.round((weight / (height * height)) * 10) / 10;
    //result = !isNaN(height) && !isNaN(weight) ? ((Math.round((weight / (height * 100) / (height * 100)) * 100000)) / 10) : null;
    return result;
  }

  riskType(question: HierarchicalQuestionObject, what: boolean, riskRating: RISK_RATING, riskMessage: string, abnormal: AbnormalityObject, riskResultMessage): AbnormalityObject {

    this.setRiskRating(question, what, riskRating, riskMessage);
    if (abnormal == undefined) {
      abnormal = {
        riskRating: riskRating,
        message: riskResultMessage
      };
    }
    else {
      abnormal.riskRating = riskRating;
      abnormal.message = riskResultMessage;
    }

    return abnormal;
  }
  /// Calculate results of the Harvard Step Test
  harvardStepTestResult(
    pulse,
    age,
    gender
  ): string {

    gender = gender.toString().toUpperCase();

    if (!Number(pulse)) {
      return "Invalid pulse rate";
    }

    if (!Number(age)) {
      return "Invalid age";
    }

    if (age < 18) {
      return "Age must be 18+";
    }

    if (gender != "MALE" && gender != "FEMALE") {
      return "Invalid gender";
    }

    let good = '65A1C48C-9033-4E36-9C5D-208788C1FF74'; // "Good to Excellent";
    let avg = '27B00687-5236-4001-BBBC-0E47731FAF91'; //"Average to Above Average";
    let poor = '0DF693AD-2C47-48F7-B4EC-6D842BE24384'; // "Poor to Fair";

    if (gender == "MALE") {
      if (age >= 18 && age <= 25) {
        if (pulse <= 84) { return good; }
        if (pulse >= 85 && pulse <= 100) { return avg; }
        if (pulse >= 101) { return poor; }
      }
      if (age >= 26 && age <= 35) {
        if (pulse <= 86) { return good; }
        if (pulse >= 87 && pulse <= 103) { return avg; }
        if (pulse >= 104) { return poor; }
      }
      if (age >= 36 && age <= 45) {
        if (pulse <= 90) { return good; }
        if (pulse >= 91 && pulse <= 106) { return avg; }
        if (pulse >= 107) { return poor; }
      }
      if (age >= 46 && age <= 55) {
        if (pulse <= 93) { return good; }
        if (pulse >= 94 && pulse <= 112) { return avg; }
        if (pulse >= 113) { return poor; }
      }
      if (age >= 56 && age <= 65) {
        if (pulse <= 96) { return good; }
        if (pulse >= 97 && pulse <= 115) { return avg; }
        if (pulse >= 116) { return poor; }
      }
      if (age >= 66) {
        if (pulse <= 102) { return good; }
        if (pulse >= 103 && pulse <= 118) { return avg; }
        if (pulse >= 119) { return poor; }
      }
    }

    if (gender == "FEMALE") {
      if (age >= 18 && age <= 25) {
        if (pulse <= 93) { return good; }
        if (pulse >= 94 && pulse <= 110) { return avg; }
        if (pulse >= 111) { return poor; }
      }
      if (age >= 26 && age <= 35) {
        if (pulse <= 94) { return good; }
        if (pulse >= 95 && pulse <= 111) { return avg; }
        if (pulse >= 112) { return poor; }
      }
      if (age >= 36 && age <= 45) {
        if (pulse <= 96) { return good; }
        if (pulse >= 97 && pulse <= 119) { return avg; }
        if (pulse >= 120) { return poor; }
      }
      if (age >= 46 && age <= 55) {
        if (pulse <= 101) { return good; }
        if (pulse >= 102 && pulse <= 124) { return avg; }
        if (pulse >= 125) { return poor; }
      }
      if (age >= 56 && age <= 65) {
        if (pulse <= 103) { return good; }
        if (pulse >= 104 && pulse <= 126) { return avg; }
        if (pulse >= 127) { return poor; }
      }
      if (age >= 66) {
        if (pulse <= 105) { return good; }
        if (pulse >= 106 && pulse <= 130) { return avg; }
        if (pulse >= 131) { return poor; }
      }
    }

    return "No result calculated";
  }

  // Calculate results for multidrug test
  multidrugTestResult(
    nonNegativeId: string,
    negativeId: string,
    resultList: string[],
    responses: ResponseItemObject[]
  ): string {
    resultList = resultList || [];
    return ArrayFunctions.any<ResponseItemObject>(i => resultList.includes(i.questionId.toLowerCase()) && i.listItemId.toLowerCase() == nonNegativeId, responses) ? nonNegativeId : negativeId;
    // let result = negativeId;
    // responses.forEach(response => {
    //   if (resultList.includes(response.questionId.toLowerCase())) {
    //     if (response.listItemId.toLowerCase() == nonNegativeId) {
    //       result = nonNegativeId;
    //     }
    //   }
    // });

    // return result;
  }

  psychosocialResult(
    q1: string,
    q2: string,
    normalId: string,
    mildId: string,
    moderateId: string,
    severeId: string,
    responses: ResponseItemObject[]
  ): string {
    let result = 'C9D3F6A5-77DD-4939-ACD0-BA58D1BE1EE9'.toLowerCase();

    let questionIds: string[] = [
      q1.toLowerCase(),
      q2.toLowerCase()
    ];

    let resultValues: Array<number> = [];
    let resultValue: number = 0;

    if (responses.find(d => d.questionId == q1.toLowerCase()))

      // Get the selected value for ph
      responses.forEach(item => {
        if (questionIds.indexOf(item.questionId.toLowerCase()) > -1) {
          let atRisk = item.question.atRisk;
          if (atRisk) {
            resultValue++;
          }
        }
      });
    if (resultValue > 0) {
      result = 'CF3DCF16-A8A2-4A74-B548-BC98B228E8EE'.toLowerCase();
    }

    return result;
  }

  psychosocial1Calculation(
    voicesQuestionId: string,
    yesAnswerId: string,
    q1: string,
    q2: string,
    q3: string,
    q4: string,
    q5: string,
    q6: string,
    q7: string,
    q8: string,
    q9: string,
    q10: string,
    lowStressId: string,
    mediumStressId: string,
    highStressId: string,
    responses: ResponseItemObject[]
  ): string {
    let result = lowStressId;

    let questionIds: string[] = [
      q1.toLowerCase(),
      q2.toLowerCase(),
      q3.toLowerCase(),
      q4.toLowerCase(),
      q5.toLowerCase(),
      q6.toLowerCase(),
      q7.toLowerCase(),
      q8.toLowerCase(),
      q9.toLowerCase(),
      q10.toLowerCase()
    ];

    let voicesRisk = responses.filter(item => item.questionId.toLowerCase() == voicesQuestionId.toLowerCase() && item.listItemId.toLowerCase() == yesAnswerId.toLowerCase()).length > 0;

    if (voicesRisk) {
      responses.filter(item => item.questionId.toLowerCase() == voicesQuestionId.toLowerCase()).forEach(item => {
        item.question.atRisk = true;
      });
    }
    let resultValues: Array<number> = [];
    let resultValue: number = 0;

    // Get the selected value for ph
    responses.forEach(item => {
      if (questionIds.indexOf(item.questionId.toLowerCase()) > -1) {
        let listItem = item.question.listItems.find(d => d.id == item.listItemId);
        if (listItem) {
          resultValues.push(+listItem.code);
          resultValue += +listItem.code;
        }
      }
    });
    if (resultValue > 13) {
      result = resultValue > 26 ? highStressId : mediumStressId;
    }
    return result;
  }

  stressResult(
    q1: string,
    normalId: string,
    mildId: string,
    moderateId: string,
    severeId: string,
    responses: ResponseItemObject[]
  ): string {
    let result = normalId.toLowerCase();

    let questionIds: string[] = [
      q1.toLowerCase()
    ];

    let resultValues: Array<number> = [];
    let resultValue: number = 0;

    if (responses.find(d => d.questionId == q1.toLowerCase()))

      // Get the selected value for ph
      responses.forEach(item => {
        if (questionIds.indexOf(item.questionId.toLowerCase()) > -1) {
          let atRisk = item.question.atRisk;
          if (atRisk) {
            resultValue++;
          }
        }
      });
    if (resultValue > 0) {
      result = 'CF3DCF16-A8A2-4A74-B548-BC98B228E8EE'.toLowerCase();
    }

    return result;
  }

  psychosocial2Calculation(
    hq: HierarchicalQuestionObject,
    q1: string,
    q2: string,
    q3: string,
    q4: string,
    normalId: string,
    mildId: string,
    moderateId: string,
    severeId: string,
    responses: ResponseItemObject[]
  ): string {
    let result = normalId;
    let questionIds: string[] = [
      q1.toLowerCase(),
      q2.toLowerCase(),
      q3.toLowerCase(),
      q4.toLowerCase()
    ];

    let resultValues: Array<number> = [];
    let resultValue: number = 0;
    responses.forEach(item => {
      if (questionIds.indexOf(item.questionId.toLowerCase()) > -1) {
        let listItem = item.question.listItems.find(d => d.id == item.listItemId);
        if (listItem) {
          resultValues.push(+listItem.code);
          resultValue += +listItem.code;
        }
      }
    });
    if (resultValue > 2) {
      result = mildId;
      if (resultValue > 5) {
        result = resultValue > 9 ? severeId : moderateId;
      }
    }
    return result;
  }

  riskScoreCalculation(
    interestQuestion: ResponseItemObject,
    feelingQuestion: ResponseItemObject,
    interestList: Array<ListItemObject>,
    feelingsList: Array<ListItemObject>,
    responses: Array<ResponseItemObject>
  ): number {
    let interestValue = interestQuestion.listItemId ? Number(interestList.find(r => r.id.toLowerCase() == interestQuestion.listItemId.toLowerCase()).code) : 0,
      feelingValue = feelingQuestion.listItemId ? Number(feelingsList.find(r => r.id.toLowerCase() == feelingQuestion.listItemId.toLowerCase()).code) : 0;
    let result = interestValue + feelingValue;
    return result;
  }

  severityRatingCalculation(
    riskScoreId: string,
    responses: Array<ResponseItemObject>
  ): string {
    let riskScoreQuestion = responses.find(r => r.questionId == riskScoreId),
      riskScore = riskScoreQuestion ? Number(riskScoreQuestion.value) : 0,
      result = riskScore > 2 ? "Major Depressive disorder is likely" : "Mental Health Condition is not likely";
    return result;
  }

  tbCalculation(
    q1y: string,
    q2y: string,
    q3y: string,
    q4y: string,
    q5y: string,
    q6y: string,
    q7y: string,
    q8y: string,
    q9n: string,
    q10y: string,
    yes: string,
    no: string,
    sputumId: string,
    clearId: string,
    normalId: string,
    abnormalId: string,
    responses: ResponseItemObject[]
  ): string {
    let result = normalId;

    let questionIds: string[] = [
      q1y.toLowerCase(),
      q2y.toLowerCase(),
      q3y.toLowerCase(),
      q4y.toLowerCase(),
      q5y.toLowerCase(),
      q6y.toLowerCase(),
      q7y.toLowerCase(),
      q8y.toLowerCase(),
      q10y.toLowerCase()
    ];

    // Get the selected value for ph
    responses.forEach(item => {
      if (item.questionId.toLowerCase() == sputumId.toLowerCase()) {
        item.question.atRisk = false;
        if (item.listItemId.toLowerCase() != clearId.toLowerCase() &&
          item.listItemId.toLowerCase() != '31A9C994-842A-4FD0-B01E-007B4F2857DD'.toLowerCase()) {
          item.question.atRisk = true;
          result = abnormalId;
        }
      }
      if (questionIds.indexOf(item.questionId.toLowerCase()) > -1) {
        item.question.atRisk = false;
        if (item.listItemId == yes) {
          item.question.atRisk = true;
          result = abnormalId;
        }
      } else if (item.questionId.toLowerCase() == q9n.toLowerCase()) {
        item.question.atRisk = false;
        if (item.listItemId == no) {
          item.question.atRisk = true;
          result = abnormalId;
        }
      }
    });
    return result;
  }

  // Calculate results for urine analysis
  urineAnalysisResult(
    phId: string,
    phLow: number,
    phHigh: number,
    phValue: number,
    sgId: string,
    sgLow: number,
    sgHigh: number,
    normalId: string,
    abnormalId: string,
    positiveId: string,
    negativeId: string,
    gender: string,
    bloodId: string,
    menstruationId: string,
    menstruationYesId: string,
    resultList: string[],
    responses: ResponseItemObject[]
  ): string {

    let result = normalId;

    // Check if any value is positive
    responses.forEach(response => {
      if (resultList.includes(response.questionId.toLowerCase())) {
        if (response.listItemId.toLowerCase() == positiveId) {
          result = abnormalId;
        }
      }
    });

    // Rules for blood in urine:
    // If Blood is positive, system checks against gender
    // - if patient is male, result is abnormal
    // - if patient is female, they are asked if they are menstruating
    //   - if patient is not menstruating, result is abnormal
    //   - if patient is menstruating, result is normal
    for (let index = 0; index < responses.length; index++) {
      if (responses[index].questionId == bloodId) {
        if (responses[index].listItemId.toLowerCase() == positiveId) {
          if (gender.toLowerCase() == 'male') {
            result = abnormalId;
          }
          else {
            let mens: ResponseItemObject = responses.find(x => x.questionId == menstruationId);
            if (mens) {
              if (mens.listItemId != menstruationYesId) {
                result = abnormalId;
              }
            }
            else {
              result = abnormalId;
            }
          }
        }
        break;
      }
    }

    responses.forEach(response => {
      if (resultList.includes(response.questionId.toLowerCase())) {
        if (response.listItemId.toLowerCase() == positiveId) {
          result = abnormalId;
        }
      }
    });

    // Check that Ph values are within range
    if (phValue) {
      if (phValue < phLow || phValue > phHigh) {
        result = abnormalId;
      }
    }

    //Check that Sg values are within range
    responses.forEach(response => {
      if (response.questionId.toLowerCase() == sgId) {
        if (response.value) {
          let sg = response.value;
          if (sg < sgLow || sg > sgHigh) {
            result = abnormalId;
          }
        }
      }
    });

    return result;
  }

  // Calculate results for vision test
  visionTestResult(
    normalId: string,
    abnormalId: string,
    farAcuityLeftId: string,
    farAcuityLeftList: string[],
    farAcuityRightId: string,
    farAcuityRightList: string[],
    farAcuityBothId: string,
    farAcuityBothList: string[],
    nearAcuityLeftId: string,
    nearAcuityLeftList: string[],
    nearAcuityRightId: string,
    nearAcuityRightList: string[],
    nearAcuityBothId: string,
    nearAcuityBothList: string[],
    visualFieldId: string,
    visualFieldList: string[],
    nightId: string,
    nightItemId: string,
    colourVisionId: string,
    colourVisionItemId: string,
    depthPerceptionId: string,
    depthPerceptionItemId: string,
    phoriaId: string,
    phoriaItemId: string,
    responses: ResponseItemObject[]
  ): string {

    let result = normalId;

    responses.forEach(response => {
      if (farAcuityLeftId == response.questionId) {
        if (farAcuityLeftList.includes(response.listItemId)) {
          result = abnormalId;
        }
      }

      if (farAcuityRightId == response.questionId) {
        if (farAcuityRightList.includes(response.listItemId)) {
          result = abnormalId;
        }
      }

      if (farAcuityBothId == response.questionId) {
        if (farAcuityBothList.includes(response.listItemId)) {
          result = abnormalId;
        }
      }

      if (nearAcuityLeftId == response.questionId) {
        if (nearAcuityLeftList.includes(response.listItemId)) {
          result = abnormalId;
        }
      }

      if (nearAcuityRightId == response.questionId) {
        if (nearAcuityRightList.includes(response.listItemId)) {
          result = abnormalId;
        }
      }

      if (nearAcuityBothId == response.questionId) {
        if (nearAcuityBothList.includes(response.listItemId)) {
          result = abnormalId;
        }
      }
      response.selectionOptionsSelected = response.selectionOptionsSelected || "";
      if (visualFieldId == response.questionId) {
        let cntFields = 0;
        let cntFieldsFound = 0;

        visualFieldList.forEach(field => {
          cntFields++;
          if (response.selectionOptionsSelected.includes(field)) {
            cntFieldsFound++;
          }
        });

        if (cntFields != cntFieldsFound) {
          result = abnormalId;
        }
      }

      if (nightId == response.questionId) {
        if (nightItemId == response.listItemId) {
          result = abnormalId;
        }
      }

      if (colourVisionId == response.questionId) {
        if (colourVisionItemId == response.listItemId) {
          result = abnormalId;
        }
      }

      if (depthPerceptionId == response.questionId) {
        if (depthPerceptionItemId == response.listItemId) {
          result = abnormalId;
        }
      }

      if (phoriaId == response.questionId) {
        if (phoriaItemId == response.listItemId) {
          result = abnormalId;
        }
      }
    });

    return result;
  }

  // Calculate results for vitals test
  vitalsTestResult(
    normalId: string,
    abnormalId: string,
    bmiId: string,
    bmiMin: number,
    bmiMax: number,
    chestInspId: string,
    chestExppId: string,
    chestDiffMin: number,
    chestDiffMax: number,
    waistId: string,
    waistMaxFemale: number,
    waistMaxMale: number,
    tempId: string,
    tempMin: number,
    tempMax: number,
    pulseId: string,
    pulseMin: number,
    pulseMax: number,
    systolicId: string,
    systolicMin: number,
    systolicMax: number,
    diastolicId: string,
    diastolicMin: number,
    diastolicMax: number,
    gender: string,
    responses: ResponseItemObject[]
  ): string {

    let result = normalId;

    let chestInsp = NaN;
    let chestExp = NaN;

    let x = gender;

    responses.forEach(response => {
      if (bmiId == response.questionId) {
        if (response.value != null) {
          if (response.value < bmiMin || response.value > bmiMax) {
            result = abnormalId;
          }
        }
      }

      if (chestInspId == response.questionId) {
        if (response.value != null) {
          chestInsp = response.value;
        }
      }

      if (chestExppId == response.questionId) {
        if (response.value != null) {
          chestExp = response.value;
        }
      }

      if (!isNaN(chestInsp) && !isNaN(chestExp)) {
        let diff = chestInsp - chestExp;
        if (diff < chestDiffMin || diff > chestDiffMax) {
          result = abnormalId;
        }
      }

      if (waistId == response.questionId) {
        if (response.value != null) {
          if (gender.toLowerCase() == 'female') {
            if (response.value > waistMaxFemale) {
              result = abnormalId;
            }
          }
          if (gender.toLowerCase() == 'male') {
            if (response.value > waistMaxMale) {
              result = abnormalId;
            }
          }
        }
      }

      if (tempId == response.questionId) {
        if (response.value != null) {
          if (response.value < tempMin || response.value > tempMax) {
            result = abnormalId;
          }
        }
      }

      if (pulseId == response.questionId) {
        if (response.value != null) {
          if (response.value < pulseMin || response.value > pulseMax) {
            result = abnormalId;
          }
        }
      }

      if (systolicId == response.questionId) {
        if (response.value != null) {
          if (response.value < systolicMin || response.value > systolicMax) {
            result = abnormalId;
          }
        }
      }

      if (diastolicId == response.questionId) {
        if (response.value != null) {
          if (response.value < diastolicMin || response.value > diastolicMax) {
            result = abnormalId;
          }
        }
      }
    });

    return result;
  }

  setRiskRating(question: HierarchicalQuestionObject, atRisk: boolean = false, riskRating: RISK_RATING = RISK_RATING.LOW, reason: string = null) {
    question.atRisk = atRisk;
    question.riskRating = riskRating;
    question.atRiskReason = reason;
  }

  adhocSnellenResult(
    normalId: string,
    abnormalId: string,
    bothAcuityId: string,
    twelveId: string,
    fifteenId: string,
    twentyId: string,
    thirtyId: string,
    sixtyId: string,
    aboveSixtyId: string,
    responses: ResponseItemObject[],
    abnormalities: AbnormalityObject[] = []
  ): string {
    let result = normalId;

    let flagValues: string[] = [twelveId, fifteenId, twentyId, thirtyId, sixtyId, aboveSixtyId];

    responses.forEach(response => {
      let abnormality: AbnormalityObject;
      if (bothAcuityId == response.questionId) {
        if (response.questionId != null) {
          if (flagValues.some(value => response.listItemId?.includes(value))) {
            this.setRiskRating(response.question, true, RISK_RATING.HIGH, 'Visual Acuity at 6 meters ≥  12.');
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Visual Acuity at 6 meters ≥  12."
            }
            result = abnormalId;
          }
        }
      }

      if (abnormality) {
        abnormalities.push(abnormality);
      }

    })

    return result;
  }

  adhocHRAResult(
    normalId: string,
    abnormalId: string,
    bmiId: string,
    bmiMin: number,
    bmiMax: number,
    pregnantId: string,
    yesId: string,
    waistId: string,
    waistMaxFemale: number,
    waistMaxMale: number,
    systolicId: string,
    systolicMin: number,
    systolicMax: number,
    diastolicId: string,
    diastolicMin: number,
    diastolicMax: number,
    cholesterolId: string,
    cholesterolMin: number,
    cholesterolMax: number,
    bloodGlucoseFirstId: string,
    bloodGlucoseSecondId: string,
    glucoseLowerBound: number,
    glucoseUpperBound: number,
    gender: string,
    responses: ResponseItemObject[],
    abnormalities: AbnormalityObject[] = []
  ): string {
    let result = normalId;

    responses.forEach(response => {
      let v = response.value;
      let q = response.question;
      let abnormality: AbnormalityObject;
      if (bmiId == response.questionId && v != 0) {
        if (pregnantId && responses.find(r => r.questionId == pregnantId && r.listItemId == yesId)) {
          this.setRiskRating(q, true, RISK_RATING.NOTICE, "BMI interpretation varies during pregnancy.");
        } else {
          this.setRiskRating(q);
          if (v != null) {
            if (v < bmiMin || v > bmiMax) {
              result = abnormalId;
              this.setRiskRating(q, true, RISK_RATING.HIGH, "A healthy BMI ranges between " + bmiMin + " and " + bmiMax);
              abnormality = {
                riskRating: RISK_RATING.HIGH,
                message: "BMI is abnormal."
              }
              if (v > 30.9) {
                q.riskRating = RISK_RATING.VERY_HIGH;
                abnormality.riskRating = RISK_RATING.VERY_HIGH;
              }
            }
          }
        }
      }

      if (waistId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (gender.toLowerCase() == 'female') {
            if (v > 87) {
              result = abnormalId;
              this.setRiskRating(q, true, RISK_RATING.HIGH, "A healthy waist circumference for women is lower than " + 88);
              abnormality = {
                riskRating: RISK_RATING.HIGH,
                message: "Waist circumference is abnormal."
              }
            }
            if (v > 100) {
              abnormality.riskRating = RISK_RATING.VERY_HIGH;
              q.riskRating = RISK_RATING.VERY_HIGH;
            }
          }

          if (gender.toLowerCase() == 'male') {
            if (v > 101) {
              result = abnormalId;
              this.setRiskRating(q, true, RISK_RATING.HIGH, "A healthy waist circumference for men is lower than " + 102);
              abnormality = {
                riskRating: RISK_RATING.HIGH,
                message: "Waist circumference is abnormal."
              }
            }

            if (v > 130) {
              q.riskRating = RISK_RATING.VERY_HIGH;
              abnormality.riskRating = RISK_RATING.VERY_HIGH;
            }
          }
        }
      }

      if (systolicId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v < systolicMin || (v > systolicMax)) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal systolic values range between " + systolicMin + " and " + systolicMax);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Systolic BP is abnormal."
            }
          }
          if (v > 160) {
            this.riskType(q, true, RISK_RATING.VERY_HIGH, "Systolic BP above " + 160 + " is a very high risk", abnormality, "Systolic BP is abnormal.");

          }
        }
      }

      if (diastolicId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v < diastolicMin || v > diastolicMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal diastolic values range between " + diastolicMin + " and " + diastolicMax);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Diastolic BP is abnormal."
            }
          }
          if (v > 100) {
            this.riskType(q, true, RISK_RATING.VERY_HIGH, "Diastolic BP above " + 100 + " is a very high risk", abnormality, "Diastolic BP is abnormal.");
          }
        }
      }

      if (cholesterolId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v < 2 || v < cholesterolMin || v > cholesterolMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Cholesterol readings range between " + cholesterolMin + " and " + cholesterolMax + " are considered healthy");
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Cholesterol is abnormal."
            }
          }
          if (v > 7.25) {
            this.riskType(q, true, RISK_RATING.VERY_HIGH, "Cholesterol levels above " + 7.25 + " are an extreme high risk", abnormality, "Cholesterol is abnormal.");
          }
        }
      }

      if ([bloodGlucoseFirstId, bloodGlucoseSecondId].indexOf(response.questionId) > -1) {
        let glucoseAbnormality = abnormalities.find(d => d.message == "Blood glucose is abnormal.");
        if (glucoseAbnormality) {
          abnormalities.splice(abnormalities.indexOf(glucoseAbnormality), 1);
        }
        this.setRiskRating(q);
        if (v != null) {
          if (v < glucoseLowerBound) {
            result = abnormalId;
            this.riskType(q, true, RISK_RATING.HIGH, "Normal blood glucose levels range between " + glucoseLowerBound + " and " + glucoseUpperBound, abnormality, "Blood glucose is abnormal.");
            // this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal blood glucose levels range between " + glucoseLowerBound + " and " + glucoseUpperBound);
            // abnormality = {
            //   riskRating: RISK_RATING.HIGH,
            //   message: "Blood glucose is abnormal."
            // }
          } else if (v > 11.01) {
            result = abnormalId;
            let riskGlucose = 11.01;
            this.riskType(q, true, RISK_RATING.VERY_HIGH, "Blood glucose levels above " + riskGlucose + " are a high risk ", abnormality, "Blood glucose is abnormal.");
          }
        }
      }


      if (abnormality) {
        abnormalities.push(abnormality);
      }

    });

    return result;
  }

  corpbreastresult(
    normalId: string,
    abnormalId: string,
    leftBreastQuestionId: string,
    rightBreastQuestionId: string,
    leftBreastResponseId: string,
    rightBreastResponseId: string,
    response: ResponseItemObject[],
    abnormalities: AbnormalityObject[] = []
  ): string {
    let result = normalId;

    response.forEach(response => {
      let v = response;
      let q = response.question;
      let s = response.listItemId;
      let abnormality: AbnormalityObject;

      if (leftBreastQuestionId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == leftBreastResponseId) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Abnormal tissue may indicate a cancer risk");
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Breast tissue is abnormal."
            }
          }
        }
      }

      if (rightBreastQuestionId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == rightBreastResponseId) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Abnormal tissue may indicate a cancer risk");
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Breast tissue is abnormal."
            }
          }
        }
      }
    });

    return result;
  }

  wohtestresult(
    contraId: string,
    mayContraId: string,
    nonContraID: string,
    dizzyId: string,
    dizzyResponseId: string,
    fitId: string,
    fitResponseId: string,
    fearSpaceId: string,
    fearSpaceResponseId: string,
    fearHeightId: string,
    fearHeightResponseId: string,
    rheumaticId: string,
    rheumaticResponseId: string,
    rheumaticSymptomId: string,
    rheumaticSymptomResponseId: string,
    bloodPressureId: string,
    bloodPressureResponseId: string,
    headacheId: string,
    headacheResponseId: string,
    mentalId: string,
    mentalResponseId: string,
    numbnessId: string,
    numbnessResponseId: string,
    backPainId: string,
    backPainResponseId: string,
    hospitalAdmissionId: string,
    hospitalAdmissionResponseId: string,
    eyeProbId: string,
    eyeProbResponseId: string,
    doubleVisionId: string,
    doubleVisionResponseId: string,
    earProbId: string,
    earProbResponseId: string,
    diffcultyHearingId: string,
    diffcultyHearingResponseId: string,
    ringingEarId: string,
    ringingEarResponseId: string,
    diabetesId: string,
    diabetesResponseId: string,
    operationId: string,
    operationResponseId: string,
    otherIllnessId: string,
    otherIllnessResponseId: string,
    responses: ResponseItemObject[],
    abnormalities: AbnormalityObject[] = []
  ): string {
    let result = nonContraID;

    responses.forEach(response => {
      let v = response;
      let q = response.question;
      let s = response.listItemId;
      let abnormality: AbnormalityObject;

      if (dizzyId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == dizzyResponseId) {
            result = contraId;
          }
        }
      }

      if (fitId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == fitResponseId) {
            result = contraId;
          }
        }
      }

      if (fearSpaceId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == fearSpaceResponseId) {
            result = contraId;
          }
        }
      }

      if (fearHeightId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == fearHeightResponseId) {
            result = contraId;
          }
        }
      }

      if (rheumaticId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == rheumaticResponseId) {
            result = mayContraId;
          }
        }
      }

      if (rheumaticSymptomId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == rheumaticSymptomResponseId) {
            result = mayContraId;
          }
        }
      }

      if (bloodPressureId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == bloodPressureResponseId) {
            result = mayContraId;
          }
        }
      }

      if (headacheId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == headacheResponseId) {
            result = mayContraId;
          }
        }
      }

      if (mentalId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == mentalResponseId) {
            result = mayContraId;
          }
        }
      }

      if (numbnessId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == numbnessResponseId) {
            result = mayContraId;
          }
        }
      }

      if (backPainId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == backPainResponseId) {
            result = mayContraId;
          }
        }
      }

      if (hospitalAdmissionId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == hospitalAdmissionResponseId) {
            result = mayContraId;
          }
        }
      }

      if (eyeProbId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == eyeProbResponseId) {
            result = mayContraId;
          }
        }
      }

      if (doubleVisionId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == doubleVisionResponseId) {
            result = mayContraId;
          }
        }
      }

      if (earProbId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == earProbResponseId) {
            result = mayContraId;
          }
        }
      }

      if (diffcultyHearingId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == diffcultyHearingResponseId) {
            result = mayContraId;
          }
        }
      }

      if (ringingEarId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == ringingEarResponseId) {
            result = mayContraId;
          }
        }
      }

      if (diabetesId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == diabetesResponseId) {
            result = mayContraId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Blood glucose above 10mmol/L, is a contraindication for working on heights");
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Blood glucose above 10mmol/L, is a contraindication for working on heights"
            }
          }
        }
      }

      if (operationId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == operationResponseId) {
            result = mayContraId;
          }
        }
      }

      if (otherIllnessId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (s == otherIllnessResponseId) {
            result = mayContraId;
          }
        }
      }
      if (abnormality)
        abnormalities.push(abnormality);

    });


    return result;
  }

  comphraResult(
    normalId: string,
    abnormalId: string,
    systolicId: string,
    systolicMin: number,
    systolicMax: number,
    diastolicId: string,
    diastolicMin: number,
    diastolicMax: number,
    bloodGlucoseFirstId: string,
    bloodGlucoseSecondId: string,
    glucoseLowerBound: number,
    glucoseUpperBound: number,
    responses: ResponseItemObject[],
    abnormalities: AbnormalityObject[] = []
  ): string {
    let result = normalId;

    responses.forEach(response => {
      let v = response.value;
      let q = response.question;
      let abnormality: AbnormalityObject;

      if (systolicId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v < systolicMin || v > systolicMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal systolic values range between " + systolicMin + " and " + systolicMax);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Systolic BP is abnormal."
            }
          }
        }
      }

      if (diastolicId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v < diastolicMin || v > diastolicMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal diastolic values range between " + diastolicMin + " and " + diastolicMax);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Diastolic BP is abnormal."
            }
          }
        }
      }
      if ([bloodGlucoseFirstId, bloodGlucoseSecondId].indexOf(response.questionId) > -1) {
        this.setRiskRating(q);
        if (v != null) {
          if (v < glucoseLowerBound || v > glucoseUpperBound) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal blood glucose levels range between " + glucoseLowerBound + " and " + glucoseUpperBound);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Blood glucose is abnormal."
            }
          }
        }
      }

      if (abnormality) {
        abnormalities.push(abnormality);
      }

    })

    return result;

  }
  biometricsResult(
    normalId: string,
    abnormalId: string,
    bmiId: string,
    bmiMin: number,
    bmiMax: number,
    waistId: string,
    waistMaxFemale: number,
    waistMaxMale: number,
    systolicId: string,
    systolicMin: number,
    systolicMax: number,
    diastolicId: string,
    diastolicMin: number,
    diastolicMax: number,
    cholesterolId: string,
    cholesterolMax: number,
    bloodGlucoseFirstId: string,
    bloodGlucoseSecondId: string,
    glucoseLowerBound: number,
    glucoseUpperBound: number,
    gender: string,
    responses: ResponseItemObject[],
    abnormalities: AbnormalityObject[] = []
  ): string {
    let result = normalId;

    responses.forEach(response => {
      let v = response.value;
      let q = response.question;
      let abnormality: AbnormalityObject;
      if (bmiId == response.questionId) {

        this.setRiskRating(q);
        if (v != null) {
          if (v < bmiMin || v > bmiMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "A healthy BMI ranges between " + bmiMin + " and " + bmiMax);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "BMI is abnormal."
            }
            if (v > 34.9) {
              q.riskRating = RISK_RATING.VERY_HIGH;
              abnormality.riskRating = RISK_RATING.VERY_HIGH;
            }
          }
        }
      }

      if (waistId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (gender.toLowerCase() == 'female') {
            if (v > 79) {
              result = abnormalId;
              this.setRiskRating(q, true, RISK_RATING.HIGH, "A healthy waist circumference for women is lower than " + 80);
              abnormality = {
                riskRating: RISK_RATING.HIGH,
                message: "Waist circumference is abnormal."
              }
            }
            if (v > 88) {
              abnormality.riskRating = RISK_RATING.VERY_HIGH;
              q.riskRating = RISK_RATING.VERY_HIGH;
            }
          }

          if (gender.toLowerCase() == 'male') {
            if (v > 94) {
              result = abnormalId;
              this.setRiskRating(q, true, RISK_RATING.HIGH, "A healthy waist circumference for men is lower than " + 95);
              abnormality = {
                riskRating: RISK_RATING.HIGH,
                message: "Waist circumference is abnormal."
              }
            }

            if (v > 102) {
              q.riskRating = RISK_RATING.VERY_HIGH;
              abnormality.riskRating = RISK_RATING.VERY_HIGH;
            }
          }
        }
      }

      if (systolicId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v < systolicMin || v > systolicMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal systolic values range between " + systolicMin + " and " + systolicMax);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Systolic BP is abnormal."
            }
          }
        }
      }

      if (diastolicId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v < diastolicMin || v > diastolicMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal diastolic values range between " + diastolicMin + " and " + diastolicMax);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Diastolic BP is abnormal."
            }
          }
        }
      }

      if (cholesterolId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v > cholesterolMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Cholesterol readings below " + cholesterolMax + " are considered healthy");
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Cholesterol is abnormal."
            }
          }
        }
      }

      if ([bloodGlucoseFirstId, bloodGlucoseSecondId].indexOf(response.questionId) > -1) {
        let glucoseAbnormality = abnormalities.find(d => d.message == "Blood glucose is abnormal.");
        if (glucoseAbnormality) {
          abnormalities.splice(abnormalities.indexOf(glucoseAbnormality), 1);
        }
        this.setRiskRating(q);
        if (v != null) {
          if (v < glucoseLowerBound || v > glucoseUpperBound) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal blood glucose levels range between " + glucoseLowerBound + " and " + glucoseUpperBound);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Blood glucose is abnormal."
            }
          }
        }
      }


      if (abnormality) {
        abnormalities.push(abnormality);
      }

    });

    return result;
  }

  wellnessResult(
    normalId: string,
    abnormalId: string,
    bmiId: string,
    bmiMin: number,
    bmiMax: number,
    waistId: string,
    waistMaxFemale: number,
    waistMaxMale: number,
    systolicId: string,
    systolicMin: number,
    systolicMax: number,
    diastolicId: string,
    diastolicMin: number,
    diastolicMax: number,
    cholesterolId: string,
    cholesterolMax: number,
    bloodGlucoseFirstId: string,
    bloodGlucoseSecondId: string,
    glucoseLowerBound: number,
    glucoseUpperBound: number,
    gender: string,
    responses: ResponseItemObject[],
    abnormalities: AbnormalityObject[] = []
  ): string {
    let result = normalId;

    responses.forEach(response => {
      let v = response.value;
      let q = response.question;
      let abnormality: AbnormalityObject;
      if (bmiId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v < bmiMin || v > bmiMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "A healthy BMI ranges between " + bmiMin + " and " + bmiMax);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "BMI is abnormal."
            }
            if (v > 34.9) {
              q.riskRating = RISK_RATING.VERY_HIGH;
              abnormality.riskRating = RISK_RATING.VERY_HIGH;
            }
          }
        }
      }

      if (waistId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (gender.toLowerCase() == 'female') {
            if (v > 79) {
              result = abnormalId;
              this.setRiskRating(q, true, RISK_RATING.HIGH, "A healthy waist circumference for women is lower than " + 80);
              abnormality = {
                riskRating: RISK_RATING.HIGH,
                message: "Waist circumference is abnormal."
              }
            }
            if (v > 88) {
              abnormality.riskRating = RISK_RATING.VERY_HIGH;
              q.riskRating = RISK_RATING.VERY_HIGH;
            }
          }

          if (gender.toLowerCase() == 'male') {
            if (v > 94) {
              result = abnormalId;
              this.setRiskRating(q, true, RISK_RATING.HIGH, "A healthy waist circumference for men is lower than " + 95);
              abnormality = {
                riskRating: RISK_RATING.HIGH,
                message: "Waist circumference is abnormal."
              }
            }

            if (v > 102) {
              q.riskRating = RISK_RATING.VERY_HIGH;
              abnormality.riskRating = RISK_RATING.VERY_HIGH;
            }
          }
        }
      }

      if (systolicId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v < systolicMin || v > systolicMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal systolic values range between " + systolicMin + " and " + systolicMax);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Systolic BP is abnormal."
            }
          }
        }
      }

      if (diastolicId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v < diastolicMin || v > diastolicMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal diastolic values range between " + diastolicMin + " and " + diastolicMax);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Diastolic BP is abnormal."
            }
          }
        }
      }

      if (cholesterolId == response.questionId) {
        this.setRiskRating(q);
        if (v != null) {
          if (v > cholesterolMax) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Cholesterol readings below " + cholesterolMax + " are considered healthy");
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Cholesterol is abnormal."
            }
          }
        }
      }

      if ([bloodGlucoseFirstId, bloodGlucoseSecondId].indexOf(response.questionId) > -1) {
        let glucoseAbnormality = abnormalities.find(d => d.message == "Blood glucose is abnormal.");
        if (glucoseAbnormality) {
          abnormalities.splice(abnormalities.indexOf(glucoseAbnormality), 1);
        }
        this.setRiskRating(q);
        if (v != null) {
          if (v < glucoseLowerBound || v > glucoseUpperBound) {
            result = abnormalId;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Normal blood glucose levels range between " + glucoseLowerBound + " and " + glucoseUpperBound);
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Blood glucose is abnormal."
            }
          }
        }
      }


      if (abnormality) {
        abnormalities.push(abnormality);
      }

    });

    return result;
  }

  fluresult(
    atRiskID,
    notRiskID,
    pregQuestionId,
    pregTestRiskId,
    triResultQuestionId,
    triResultTestRiskId,
    feverResultQuestionId,
    feverResultTestRiskId,
    sensitiveResultQuestionId,
    sensitiveResultTestRiskId,
    responseItems: ResponseItemObject[],
    abnormalities: AbnormalityObject[] = []
  ): string {
    let result = notRiskID;

    responseItems.forEach(response => {
      let v = response.value;
      let q = response.question;
      let s = response.listItemId;
      let sos = response.selectionOptionsSelected;
      let abnormality: AbnormalityObject;

      if (pregQuestionId == response.questionId) {
        this.setRiskRating(q)
        if (s != null) {
          if (s == pregTestRiskId) {
            result = atRiskID;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Pregnancy test is positive");
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Pregnancy test is positive."
            }
          }
        }
      }

      if (triResultQuestionId == response.questionId) {
        this.setRiskRating(q)
        if (s != null) {
          if (s == triResultTestRiskId) {
            result = atRiskID;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Flu vaccine is a risk for pregnant women in 2nd trimester");
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Flu vaccine risk for pregant women in 2nd trimester."
            }
          }
        }
      }

      if (feverResultQuestionId == response.questionId) {
        this.setRiskRating(q)
        if (s != null) {
          if (s == feverResultTestRiskId) {
            result = atRiskID;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Fever in the last 14 days");
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Fever in the last 14 days."
            }
          }
        }
      }

      if (sensitiveResultQuestionId == response.questionId) {
        this.setRiskRating(q)
        if (s != null) {
          if (s == sensitiveResultTestRiskId) {
            result = atRiskID;
            this.setRiskRating(q, true, RISK_RATING.HIGH, "Hypersensitive or allergic to flu vaccine");
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: "Hypersensitive or allergic to flu vaccine."
            }
          }
        }
      }

      if (abnormality) {
        abnormalities.push(abnormality);
      }
    })

    return result;
  }

  papsmear(
    collected,
    notCollected,
    sampleQuestionId,
    sampleResult,
    responses: ResponseItemObject[],
    abnormalities: AbnormalityObject[] = []
  ): string {
    let result = notCollected;

    responses.forEach(response => {
      let v = response.value;
      let q = response.question;
      let s = response.listItemId;
      let sos = response.selectionOptionsSelected;
      let abnormality: AbnormalityObject;

      if (sampleQuestionId == response.questionId) {
        this.setRiskRating(q)
        if (s != null) {
          if (s == sampleResult) {
            result = collected;
            this.setRiskRating(q, true, RISK_RATING.LOW, "Thanks for confirming");
            abnormality = {
              riskRating: RISK_RATING.LOW,
              message: "Specimen results sent to lab."
            }
          }
        }
      }
      if (abnormality) {
        abnormalities.push(abnormality);
      }
    })
    return result;
  }

  psaresult(
    atRiskID,
    notRiskID,
    prevTestQuestionId,
    prevTestRiskId,
    prostateQuestionId,
    prostateTestRiskId,
    dietQuestionId,
    dietTestRiskId,
    ageQuestionId,
    maxAge,
    urineQuestionId,
    testResultId,
    testReusltQuestionId,
    responses: ResponseItemObject[],
    abnormalities: AbnormalityObject[] = []
  ): string {
    let result = notRiskID;

    responses.forEach(response => {
      let v = response.value;
      let q = response.question;
      let s = response.listItemId;
      let sos = response.selectionOptionsSelected;
      let urineRiskItems = ["F4073D0A-58FC-46CD-B0BE-00B4E1993DE5", "3908EE10-EB68-4070-A744-4FCD5C6951AF", "5E3AA93B-D977-41C1-91B8-5863E3FE7F88", "5A989634-04E5-4D50-9515-991199D83E2F", "BBF99FCA-0EC6-4806-AFE5-9FB71EEFFB8C", "AA7C9238-9FA9-4591-AF23-FC7AF7894CDB"]
      let abnormality: AbnormalityObject;
      if (prostateQuestionId == response.questionId) {
        this.setRiskRating(q)
        if (s != null) {
          if (s == prostateTestRiskId) {
            result = atRiskID
            this.setRiskRating(q, true, RISK_RATING.HIGH)
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: 'History of prostate cancer in family.',
            }
          }
        }
      }
      if (prevTestQuestionId == response.questionId) {
        this.setRiskRating(q)
        if (s != null) {
          if (s == prevTestRiskId) {
            result = atRiskID
            this.setRiskRating(q, true, RISK_RATING.HIGH)
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: 'Previous test result was High Risk.',
            }
          }
        }
      }

      if (dietQuestionId == response.questionId) {
        this.setRiskRating(q)
        if (s != null) {
          if (s == dietTestRiskId) {
            result = atRiskID
            this.setRiskRating(q, true, RISK_RATING.HIGH)
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: 'Diet indicates a prostate cancer risk.',
            }
          }
        }
      }

      if (ageQuestionId == response.questionId) {
        this.setRiskRating(q)
        if (v != null) {
          if (v >= maxAge) {
            result = atRiskID
            this.setRiskRating(q, true, RISK_RATING.HIGH)
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: 'Age greater than 40.',
            }
          }
        }
      }

      if (urineQuestionId == response.questionId) {
        this.setRiskRating(q)
        if (sos && sos.length > 0 && urineRiskItems.some(item => sos.toUpperCase().includes(item))) {
          result = atRiskID
          this.setRiskRating(q, true, RISK_RATING.HIGH)
          abnormality = {
            riskRating: RISK_RATING.HIGH,
            message: 'Urination indicates a risk for prostate cancer.',
          }
        }
      }

      if (testReusltQuestionId == response.questionId) {
        this.setRiskRating(q)
        if (s != null) {
          if (s == testResultId) {
            result = atRiskID
            this.setRiskRating(q, true, RISK_RATING.HIGH)
            abnormality = {
              riskRating: RISK_RATING.HIGH,
              message: 'Previous test result was High Risk.',
            }
          }
        }
      }


      if (abnormality) {
        abnormalities.push(abnormality);
      }

    });

    return result;
  }
}

