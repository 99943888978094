import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsultationObject } from 'src/app/shared/interfaces/consultationMapper';
import { RiskService } from 'src/app/shared/services/risk.service';

@Component({
  selector: 'app-risk-summary',
  templateUrl: './risk-summary.component.html',
  styleUrls: ['./risk-summary.component.scss']
})
export class RiskSummaryComponent implements OnInit {
  // Add this line
  Object = Object;
  isLoading: boolean = false;
  consultationDetail: ConsultationObject;
  reviewIds: any;
  isCVROnPage: boolean = false;
  isCVRDetails: boolean = false;
  isCVRData: boolean = true;
  cvrData: any;
  filteredTestPerformed: any;
  noData: string;
  hraReviewId: any;
  selectedHRAReviewId: any;
  isDropdown: boolean = false;

  gaugeObject: any;
  selectedYear: any;
  years: any;
  cvrMapperData: any;

  consent = ["CORPORATECONSENT", "CORPFLUCONSENT", "CORPPSACONSENT", "CORPPAPCONSENT", "CORPORATEHIVCONSENT"]

  cvrMapper = [
    { "Medical History": "PHRAWS01", "Color": "" },
    { "Smoking": "PHRAWS06", "Color": "" },
    { "Physical Activity": "PHRAWS05", "Color": "" },
    { "Alcohol use": "PHRAWS11", "Color": "" },
    { "BMI (kg/m^3)": "BMI", "Color": "" },
    { "Waist Circumference (cm)": "WAIST", "Color": "" },
    { "Blood sugar (mmoll)": "BLOODGLUCOSE", "Color": "" },
    { "Cholesterol (mmoll)": "CHOLESTEROL", "Color": "" },
    { "Systolic (mmHg)": "SYSTOLIC", "Color": "" },
    { "Diastolic (mmHg)": "DIASTOLIC", "Color": "" },
    { "Actions taken": "REFERRED", "Color": "" }
  ];

  hivTBMapper = [
    { "HIV test result": "HIVTESTRESULT", "Color": "" },
    { "Voluntary counselling": "ADVCRESULT2", "Color": "" },
    { "Tuberculosis risk": "TBRESULT", "Color": "" }
  ];

  cancerMapper = [
    { "Prior screening": "COMBRESULT", "Color": "" },
    { "Breast Screening": "corpbreastresult", "Color": "" },
    { "PSA Test": "PSARESULTTEST", "Color": "" }
  ];

  pyschoMapper = [
    { "Stress Questionnaire Rating": "STRESSSCORE1", "Color": "" },
    { "Mental Wellbeing Rating": "MWBScore", "Color": "" }
  ];

  public cvrcanvasWidth = 250;
  public cvrneedleValue = 0;
  public cvrcentralLabel = '';
  public name = 'Gauge chart';
  public bottomLabel = '65';
  public cvroptions = {
    hasNeedle: true,
    needleColor: 'black',
    needleUpdateSpeed: 500,
    //arcColors: ['red', 'orange', 'green'],
    //arcDelimiters: [33, 66],
    arcColors: ['green', 'green', 'orange', 'orange', 'red', 'red'],
    arcDelimiters: [15, 35, 50, 65, 80], // Adjusted to have two delimiters
    //rangeLabel: ['0', '100'],
    needleStartValue: 50,
    // Add labels for each section
    arcLabels: ['Low', '', 'Medium', '', 'High'], // This assumes the component supports an arcLabels property
  };


  //TBHIV gauge variables
  isHIVTBData: boolean = false;
  isHivTBDropdown: boolean = false;
  isHIVTBTable: boolean = false;
  hivTBData: any;
  HIVData: any;
  HIVVoluntaryData: any;
  TBQuestionnaireData: any;
  HRAData: any;
  hivTBMapperData: any;
  public hivTBneedleValue = 0;
  public hivTboptions = {
    hasNeedle: true,
    needleColor: 'black',
    needleUpdateSpeed: 500,
    //arcColors: ['red', 'orange', 'green'],
    //arcDelimiters: [33, 66],
    arcColors: ['green', 'green', 'orange', 'orange', 'red', 'red'],
    arcDelimiters: [15, 35, 50, 65, 80], // Adjusted to have two delimiters
    //rangeLabel: ['0', '100'],
    needleStartValue: 50,
    // Add labels for each section
    arcLabels: ['Low', '', 'Medium', '', 'High'], // This assumes the component supports an arcLabels property
  };


  //cancer gauge variables
  isCancerData: boolean = false;
  isCancerDropdown: boolean = false;
  isCancerTable: boolean = false;
  cancerData: any;
  breastCancerData: any;
  psaCancerData: any;
  HRACancerData: any;
  cancerMapperData: any;
  public cancerneedleValue = 0;
  public canceroptions = {
    hasNeedle: true,
    needleColor: 'black',
    needleUpdateSpeed: 500,
    //arcColors: ['red', 'orange', 'green'],
    //arcDelimiters: [33, 66],
    arcColors: ['green', 'green', 'orange', 'orange', 'red', 'red'],
    arcDelimiters: [15, 35, 50, 65, 80], // Adjusted to have two delimiters
    //rangeLabel: ['0', '100'],
    needleStartValue: 50,
    // Add labels for each section
    arcLabels: ['Low', '', 'Medium', '', 'High'], // This assumes the component supports an arcLabels property
  };

  //psychosocial gauge variables
  isPsychosocialData: boolean = false;
  isPsychosocialDropdown: boolean = false;
  isPsychosocialTable: boolean = false;
  psychosocialData: any;
  psychosocialMapperData: any;
  mentalWellbeingData: any;
  stressData: any;
  public psychosocialneedleValue = 0;
  public psychosocialoptions = {
    hasNeedle: true,
    needleColor: 'black',
    needleUpdateSpeed: 500,
    //arcColors: ['red', 'orange', 'green'],
    //arcDelimiters: [33, 66],
    arcColors: ['green', 'green', 'orange', 'orange', 'red', 'red'],
    arcDelimiters: [15, 35, 50, 65, 80], // Adjusted to have two delimiters
    //rangeLabel: ['0', '100'],
    needleStartValue: 50,
    // Add labels for each section
    arcLabels: ['Low', '', 'Medium', '', 'High'], // This assumes the component supports an arcLabels property
  }

  constructor(private router: Router,
    private route: ActivatedRoute,
    private riskService: RiskService) {
    if (history?.state) {
      this.consultationDetail = history.state['data'];
      this.reviewIds = history.state['reviewId'];


      let obj = [];
      this.filteredTestPerformed = this.consultationDetail.testsRequested.filter(x => x.testsPerformed.length > 0);
      this.filteredTestPerformed = this.filteredTestPerformed.filter(x => !this.consent.includes(x.testMapping.workflowId));
      if (this.filteredTestPerformed.length > 0) {

        this.filteredTestPerformed.forEach(x => {
          let data = this.getGaugeData(x.testMapping.ocsaname)
          obj.push(data)
        })
        this.gaugeObject = obj;
        console.log("Review data", obj);
      }
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.gaugeSettingForCVR(this.gaugeObject);
    this.gaugeSettingForHIVTB(this.gaugeObject);
    this.gaugeSettingForCancer(this.gaugeObject);
    this.gaugeSettingForPsychosocial(this.gaugeObject);
    this.isLoading = false;
  }

  getGaugeData(ocsaname) {
    let res = {
      ocName: ocsaname,
      data: {}
    }
    if (this.filteredTestPerformed.filter(x => x.testMapping.ocsaname == ocsaname && ocsaname != null)[0]?.testsPerformed?.length > 0) {
      let tpData = this.filteredTestPerformed.filter(x => x.testMapping.ocsaname == ocsaname && x.testsPerformed.reviewId != "00000000-0000-0000-0000-000000000000")[0].testsPerformed[0]
      let review = this.reviewIds.filter(x => x.testMappingId == tpData.testMappingId)
      if (review.length > 0) {

        let year = new Date(tpData.createDate).getFullYear();
        if (review.filter(x => x.reviewId == tpData.reviewId && x.year == year)) {
          res.data = (review.filter(x => x.reviewId == tpData.reviewId && x.year == year)[0])
          return res;
        }
        else {
          res.data = (review[0]);
          return res;
        }
      }
    }
    // else {
    //   return res.data = (false);
    // }
  }

  gaugeSettingForCVR(obj) {
    let d = obj.filter(x => x.ocName == "HRA");
    if (d.length > 0) {
      d = d[0];
      this.cvrData = d;
      //this.showCVRDetails();
      // this.conditionTosetGagueNeddle(d.data.testPerformed);
      // console.log("Gauge data", d.data);
      // this.setCVRNeddleValue(d)
      this.isCVROnPage = true; //if this is commented this.showCVRDetails() on line 129
    }
    else {
      this.isCVRData = false;
    }
  }

  setCVRNeddleValue(d) {
    if (d.data.testPerformed.resultInEnglish == "Abnormal") {
      this.cvrneedleValue = 25;
    }
    else if (d.data.testPerformed.resultInEnglish == "Normal") {
      this.cvrneedleValue = 85;
    }
    else if (d.data.testPerformed.result >= 2 && d.data.testPerformed.result <= 9) {
      this.isCVRData = false;
    }
    else {
      this.cvrneedleValue = 50;
    }
  }

  conditionTosetGagueNeddle(tp) {
    switch (tp.result) {
      case 0:
        if (tp.questionnaireResult) {
          if (tp.questionnaireResult == 0) {
            tp.resultInEnglish = 'Normal';
          }
          else {
            if (tp.questionnaireResult == 1) {
              tp.resultInEnglish = 'Abnormal'
            }
            else {
              tp.resultInEnglish = 'Normal';
            }
          }
        }
        else {
          tp.resultInEnglish = 'Normal';
        }
        break;
      case 1:
        tp.resultInEnglish = 'Abormal';
        break;
      case 2:
        tp.resultInEnglish = 'Test Not Done';
        break;
      case 3:
        tp.resultInEnglish = 'Contraindicated';
        break;
      case 4:
        tp.resultInEnglish = 'Awaiting Test Results';
        break;
      case 5:
        tp.resultInEnglish = 'Not Required';
        break;
      case 6:
        tp.resultInEnglish = 'Consent Given';
        break;
      case 7:
        tp.resultInEnglish = 'Consent Refused';
        break;
      case 8:
        tp.resultInEnglish = 'Test Refused';
        break;
      case 9:
        tp.resultInEnglish = 'Test In Progress';
        break;
      default:
        tp.resultInEnglish = 'Unknown';
        break;
    }
  }

  back(): void {
    this.router.navigate(['/wellness-event/corporate/consultation']);
  }

  showDetails(data: any, type: string) {
    switch (data) {
      case "CVR":
        if (type == "G") {
          if (this.isCVRData) {
            this.selectedYear = this.cvrData?.data.year;
            this.setYearDropdown(this.cvrData?.data.testMappingId)
            this.showCVRDetails();
          }
        }
        else {
          let tmId = this.cvrData?.data.testMappingId;
          this.cvrData.data = this.reviewIds.filter(x => x.testMappingId == tmId && x.year == this.selectedYear)[0];
          this.showCVRDetails();
        }
        break;
      case "HIVTB":
        if (type == "G") {
          if (this.isHIVTBData) {
            if (this.reviewIds.some(x => x.testMappingId == this.HIVData?.data.testMappingId)) {
              this.selectedYear = this.HIVData?.data.year;
              this.setYearDropdown(this.HIVData?.data.testMappingId)
            }
            else if (this.reviewIds.some(x => x.testMappingId == this.HIVVoluntaryData?.data.testMappingId)) {
              this.selectedYear = this.HIVVoluntaryData?.data.year;
              this.setYearDropdown(this.HIVVoluntaryData?.data.testMappingId)
            }
            else {
              this.selectedYear = this.TBQuestionnaireData?.data.year;
              this.setYearDropdown(this.TBQuestionnaireData?.data.testMappingId)
            }
            this.showHIVTBDetails();
          }
        }
        else {
          let hivTmId = this.HIVData?.data.testMappingId;
          if (hivTmId != undefined)
            this.HIVData.data = this.reviewIds.filter(x => x.testMappingId == hivTmId && x.year == this.selectedYear)[0];
          else
            this.HIVData = undefined;

          let volTmId = this.HIVVoluntaryData?.data.testMappingId;
          if (volTmId != undefined)
            this.HIVVoluntaryData.data = this.reviewIds.filter(x => x.testMappingId == volTmId && x.year == this.selectedYear)[0];

          let tbTmId = this.TBQuestionnaireData?.data.testMappingId;
          if (tbTmId != undefined)
            this.TBQuestionnaireData.data = this.reviewIds.filter(x => x.testMappingId == tbTmId && x.year == this.selectedYear)[0];
          else
            this.TBQuestionnaireData = undefined;

          let hraTmId = this.HRAData?.data.testMappingId;
          if (hraTmId != undefined)
            this.HRAData.data = this.reviewIds.filter(x => x.testMappingId == hraTmId && x.year == this.selectedYear)[0];
          else
            this.HRAData = undefined;

          this.showHIVTBDetails();
        }
        break;
      case "CANCER":
        if (type == "G") {
          if (this.isCancerData) {
            if (this.reviewIds.some(x => x.testMappingId == this.breastCancerData?.data.testMappingId)) {
              this.selectedYear = this.breastCancerData?.data.year;
              this.setYearDropdown(this.breastCancerData?.data.testMappingId)
            }
            else {
              this.selectedYear = this.psaCancerData?.data.year;
              this.setYearDropdown(this.psaCancerData?.data.testMappingId)
            }
            this.showCancerDetails();
          }
        }
        else {
          let bTmId = this.breastCancerData?.data.testMappingId;
          if (bTmId != undefined)
            this.breastCancerData.data = this.reviewIds.filter(x => x.testMappingId == bTmId && x.year == this.selectedYear)[0];
          else
            this.breastCancerData = undefined;

          let pTmId = this.psaCancerData?.data.testMappingId;
          if (pTmId != undefined)
            this.psaCancerData.data = this.reviewIds.filter(x => x.testMappingId == pTmId && x.year == this.selectedYear)[0];
          else
            this.psaCancerData = undefined;

          let hraTmId = this.HRACancerData?.data.testMappingId;
          if (hraTmId != undefined)
            this.HRACancerData.data = this.reviewIds.filter(x => x.testMappingId == hraTmId && x.year == this.selectedYear)[0];
          else
            this.HRACancerData = undefined;

          this.showCancerDetails();
        }
        break;
      case "PSYCO":
        if (type == "G") {
          if (this.isPsychosocialData) {
            if (this.reviewIds.some(x => x.testMappingId == this.mentalWellbeingData?.data.testMappingId)) {
              this.selectedYear = this.mentalWellbeingData?.data.year;
              this.setYearDropdown(this.mentalWellbeingData?.data.testMappingId)
            }
            else {
              this.selectedYear = this.stressData?.data.year;
              this.setYearDropdown(this.stressData?.data.testMappingId)
            }
            this.showPsychosocialDetails();
          }
        }
        else {
          let mwmId = this.mentalWellbeingData?.data.testMappingId;
          if (mwmId != undefined)
            this.mentalWellbeingData.data = this.reviewIds.filter(x => x.testMappingId == mwmId && x.year == this.selectedYear)[0];
          else
            this.mentalWellbeingData = undefined;

          let smId = this.stressData?.data.testMappingId;
          if (smId != undefined)
            this.stressData.data = this.reviewIds.filter(x => x.testMappingId == smId && x.year == this.selectedYear)[0];
          else
            this.stressData = undefined;
          //this.showPsycoDetails();
          this.showPsychosocialDetails();
        }
        break;
      default:
        break;
    }
  }

  setYearDropdown(testMappingId) {
    let review = this.reviewIds.filter(x => x.testMappingId == testMappingId)
    this.years = review
      .map(item => item.year)        // Extract the years
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  showCVRDetails() {
    if (this.isCVRData) {
      this.isLoading = true;
      let reviewId = this.cvrData.data.reviewId;
      this.riskService.getHRAResponseByReviewId(reviewId).subscribe(
        (res) => {
          if (this.isCVROnPage) {
            this.hideDropdown_Table();
            this.isCVRDetails = true;
            this.isDropdown = true;
            this.selectedYear = this.cvrData.data.year;
            this.cvrMapperData = this.mapResponseToFields(this.cvrMapper, res.result);
          }
          this.isCVROnPage = true;
          // this.conditionTosetGagueNeddle(this.cvrData.data.testPerformed);
          // this.setCVRNeddleValue(this.cvrData)
          this.cvrneedleValue = res.needleValue;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          console.log(err);
        }
      )
    }

  }

  gaugeSettingForHIVTB(obj) {
    let isAvailable = obj.some(x => ["HIV", "HIVVoluntary", "TBQuestionnaire"].includes(x.ocName));
    if (isAvailable) {
      this.isHIVTBData = true;
      let HIV = obj.filter(x => x.ocName == "HIV")
      if (HIV.length > 0) {
        this.HIVData = HIV[0];
      }
      let HIVVoluntary = obj.filter(x => x.ocName == "HIVVoluntary")
      if (HIVVoluntary.length > 0) {
        this.HIVVoluntaryData = HIVVoluntary[0];
      }
      let TBQuestionnaire = obj.filter(x => x.ocName == "TBQuestionnaire")
      if (TBQuestionnaire.length > 0) {
        this.TBQuestionnaireData = TBQuestionnaire[0];
      }
      let HRA = obj.filter(x => x.ocName == "HRA")
      if (HRA.length > 0) {
        this.HRAData = HRA[0];
      }

    }
    else {
      this.isHIVTBData = false;
    }
  }

  showHIVTBDetails() {
    if (this.isHIVTBData) {
      let apiObj = {
        hivId: "",
        vcId: "",
        tbId: "",
        hraId: "",
      }
      apiObj.hivId = this.HIVData?.data.reviewId;

      apiObj.vcId = this.HIVVoluntaryData?.data.reviewId;

      apiObj.tbId = this.TBQuestionnaireData?.data.reviewId;

      apiObj.hraId = this.HRAData?.data.reviewId;
      this.isLoading = true;
      this.riskService.getHIVTVResponseByReviewId(apiObj).subscribe(
        (res) => {
          this.isHIVTBData = true;
          res.result.forEach(x => {
            if (x.questionCode == "ADVCRESULT2") {
              if (x.genericResponse == "Abnormal") {
                x.genericResponse = "At risk";
                x.color = "red";
              }
              else {
                x.genericResponse = "Not at risk";
                x.color = "green";
              }
            }
          })
          this.hivTBData = res.result.filter(x => ["ADVCRESULT2", "HIVTESTRESULT", "TBRESULT"].includes(x.questionCode));
          this.hivTBMapperData = this.mapResponseToFields(this.hivTBMapper, this.hivTBData);
          this.hivTBneedleValue = res.needleValue;
          this.hideDropdown_Table();
          this.isHivTBDropdown = true;
          this.isHIVTBTable = true;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          console.log(err);
        }
      )
    }
  }

  gaugeSettingForCancer(obj) {
    let isAvailable = obj.some(x => ["BREAST", "PSA"].includes(x.ocName));
    if (isAvailable) {
      this.isCancerData = true;
      let breast = obj.filter(x => x.ocName == "BREAST")
      if (breast.length > 0) {
        this.breastCancerData = breast[0];
      }
      let psa = obj.filter(x => x.ocName == "PSA")
      if (psa.length > 0) {
        this.psaCancerData = psa[0];
      }
      let HRA = obj.filter(x => x.ocName == "HRA")
      if (HRA.length > 0) {
        this.HRACancerData = HRA[0];
      }

    }
    else {
      this.isCancerData = false;
    }
  }

  showCancerDetails() {
    if (this.isCancerData) {
      let apiObj = {
        breastId: "",
        psaId: "",
        hraId: "",
      }
      apiObj.breastId = this.breastCancerData?.data.reviewId;

      apiObj.psaId = this.psaCancerData?.data.reviewId;

      apiObj.hraId = this.HRACancerData?.data.reviewId;

      this.isLoading = true;
      this.riskService.getCancerResponseByReviewId(apiObj).subscribe(
        (res) => {
          this.cancerData = res.result.filter(x => ["PSARESULTTEST", "corpbreastresult", "COMBRESULT"].includes(x.questionCode));
          this.cancerMapperData = this.mapResponseToFields(this.cancerMapper, this.cancerData);
          this.cancerneedleValue = res.needleValue;
          this.hideDropdown_Table();
          this.isCancerDropdown = true;
          this.isCancerTable = true;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          console.log(err);
        }
      )
    }
  }

  gaugeSettingForPsychosocial(obj) {
    let isAvailable = obj.some(x => ["MentalWellbeing", "Stress"].includes(x.ocName));
    if (isAvailable) {
      this.isPsychosocialData = true;
      let mw = obj.filter(x => x.ocName == "MentalWellbeing")
      if (mw.length > 0) {
        this.mentalWellbeingData = mw[0];
      }
      let s = obj.filter(x => x.ocName == "Stress")
      if (s.length > 0) {
        this.stressData = s[0];
      }
    }
    else {
      this.isCancerData = false;
    }
  }

  showPsychosocialDetails() {
    if (this.isPsychosocialData) {
      let apiObj = {
        StressId: "",
        MentalId: ""
      }
      apiObj.StressId = this.stressData?.data.reviewId;

      apiObj.MentalId = this.mentalWellbeingData?.data.reviewId;

      this.isLoading = true;
      this.riskService.getPsychosocialResponseByReviewId(apiObj).subscribe(
        (res) => {
          this.psychosocialData = res.result.filter(x => ["STRESSSCORE1", "MWBScore"].includes(x.questionCode));
          this.psychosocialMapperData = this.mapResponseToFields(this.pyschoMapper, this.psychosocialData);
          this.psychosocialneedleValue = res.needleValue;
          this.hideDropdown_Table();
          this.isPsychosocialDropdown = true;
          this.isPsychosocialTable = true;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          console.log(err);
        }
      )
    }
  }

  hideDropdown_Table() {
    //cvr hide
    this.isDropdown = false;
    this.isCVRDetails = false;

    //hivtb hide
    this.isHivTBDropdown = false;
    this.isHIVTBTable = false;

    //cancer hide
    this.isCancerDropdown = false;
    this.isCancerTable = false;

    //psychosocial hide
    this.isPsychosocialDropdown = false;
    this.isPsychosocialTable = false;

  }

  mapResponseToFields(mapper: Array<{ [key: string]: string }>, responseData: Array<any>): Array<{ label: string | null; response: any; color: any }> {
    // Input validation
    if (!mapper || !responseData || !Array.isArray(responseData)) {
      throw new Error('Invalid input: mapper and responseData array are required');
    }

    // Create the mapped object using reduce
    return mapper.reduce((acc, item) => {
      // Get the key of the current item (e.g., "Medical History")
      const field = Object.keys(item)[0];  // This will give you the field name, like "Medical History"
      const questionCode = item[field];    // This will give you the corresponding questionCode like "PHRAWS01"

      // Find matching response by questionCode
      const matchingResponse = responseData.find(response => response.questionCode === questionCode);

      // If a matching response is found, add both genericResponse and color
      if (matchingResponse) {
        acc.push({
          label: field,
          response: matchingResponse.genericResponse,
          value: matchingResponse.value,
          color: matchingResponse.color || null // Safely handle if color is not available
        });
      } else {
        // If no matching response is found, set both response and color to null
        acc.push({
          label: field,
          response: null,
          value: null,
          color: null
        });
      }

      return acc;
    }, []);  // Initialize the accumulator as an empty array
  }


}
