<three-bar-loader *ngIf="isLoading"></three-bar-loader>
<h4>Edit Member Claim</h4>
<mat-dialog-content>
  <form [formGroup]="editForm">

    <div class="row">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" required>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Surname</mat-label>
        <input matInput formControlName="surname" required>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label>Member Number</mat-label>
        <input matInput formControlName="memberNumber" required>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Dependent Code</mat-label>
        <input matInput formControlName="dependantCode" required>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label>Scheme Code</mat-label>
        <mat-select formControlName="schemeCode" required>
          <mat-option *ngFor="let scheme of schemeCodes" [value]="scheme.code">
            {{scheme.code}} - {{scheme.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field>
        <mat-label>Date of Birth</mat-label>
        <input matInput [matDatepicker]="dobPicker" formControlName="dob" required>
        <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
        <mat-datepicker #dobPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-checkbox (change)="onSelect($event.checked)" formControlName="NonMember">
        Non-Member
      </mat-checkbox>
      <mat-checkbox formControlName="claimPaid" style="margin-left: 3%;">
        Claim Paid
      </mat-checkbox>
    </div>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button [ngClass]="{ 'mdc-button--raised': editForm.valid && editForm.dirty, 'mdc-ripple-upgraded': editForm.valid && editForm.dirty,
    'mdc-button__fill--darkblue': editForm.valid , 'mdc-button': editForm.valid && editForm.dirty, 'mat-raised-button': editForm.invalid || editForm.pristine,
    'text-color': editForm.valid }" (click)="onSave()" [disabled]="!editForm.valid">Save</button>
</mat-dialog-actions>