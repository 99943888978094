import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';

import { DtoBookingDoc } from '../../interfaces/wellness-event-booking';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {
  uploadForm: FormGroup;
  selectedFile: File = null;
  isLoading = false;
  username: string = '';

  constructor(
    private dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private keycloak: KeycloakService
  ) { }

  ngOnInit(): void {
    this.uploadForm = this.fb.group({
      file: [null, Validators.required],
      docName: ['']
    });

    this.keycloak.loadUserProfile().then(profile => {
      this.username = profile.firstName + ' ' + profile.lastName || '';
    });
  }

  onFileSelected(event: any): void {
    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0] as File;
      this.uploadForm.get('file').setValue(this.selectedFile);

      const fileName = this.selectedFile.name;
      const docName = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
      this.uploadForm.get('docName').setValue(docName);
    }
  }

  Submit(): void {
    if (this.uploadForm.valid) {
      this.isLoading = true;
      // Create the DtoBookingDoc object
      const bookingDoc: DtoBookingDoc = {
        file: this.selectedFile,
        docName: this.uploadForm.get('docName').value,
        contentType: this.selectedFile.type,
        uploadedBy: this.username
      };

      setTimeout(() => {
        this.isLoading = false;
        this.dialogRef.close({
          file: this.selectedFile,
          bookingDocument: bookingDoc
        });
      }, 500);

    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
