<div class="container">
  <article class="grid">
    <span *ngIf="isLoading === true" class="blank-line blank-line-4 d-block"></span>

    <div *ngIf="isFailed === false">
      <section class="mdc-typography--body1">
        <h1 class="mdc-typography--headline2 text--align-center">User management</h1>
      </section><br><br>
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell">
          <div class="test-list">
            <ng-container>
              <div class="row">
                <div class="grid__cell" style="text-align: -webkit-center">
                  <img *ngIf="true" class="" style="height: 40px" src="assets/img/consultation/not_completed.svg"
                    alt="ico">
                  <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
                </div>
                <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
                  <div class="row">
                    <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
                      <img class="" style="height: 40px" src="assets\img\consultation\questions.svg" alt="ico">
                    </div>
                    <div style="width: 90%" class="grid__cell">
                      <div class="grid__cell mdc-typography--subtitle1">
                        <span>Reset Password</span>
                      </div>
                    </div>
                    <div class="grid__cell" style="width: 20%">
                      <button style="width: 150px;"
                        class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                        (click)="goToPwdRest()">Open</button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <!--Invoicing start from here-->
              <!-- <div class="row">
                <div class="grid__cell" style="text-align: -webkit-center">
                  <img *ngIf="true" class="" style="height: 40px" src="assets/img/consultation/not_completed.svg"
                    alt="ico">
                  <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
                </div>
                <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
                  <div class="row">
                    <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
                      <img class="" style="height: 40px" src="assets\img\consultation\questions.svg" alt="ico">
                    </div>
                    <div style="width: 90%" class="grid__cell">
                      <div class="grid__cell mdc-typography--subtitle1">
                        <span>Fix the claim</span>

                      </div>
                    </div>

                    <div class="grid__cell" style="width: 20%">
                      <button style="width: 150px;"
                        class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                        (click)="goToUserManagement()">Start</button>
                    </div>
                  </div>
                </div>
              </div> -->
              <!--Invoicing end from here-->
              <br />
              <br />

              <!--Report start from here-->
              <!-- <div class="row">
                <div class="grid__cell" style="text-align: -webkit-center">
                  <img *ngIf="true" class="" style="height: 40px" src="assets/img/consultation/not_completed.svg"
                    alt="ico">
                  <div style="background-color: #e9e9e9; height: 100%; width: 3px"></div>
                </div>
                <div style="width: 90%" class="grid__cell grid__cell--align-stretch bg-white p-3">
                  <div class="row">
                    <div style="width: 20%; align-content: center; max-width: 52px" class="grid__cell">
                      <img class="" style="height: 40px" src="assets\img\consultation\questions.svg" alt="ico">
                    </div>
                    <div style="width: 90%" class="grid__cell">
                      <div class="grid__cell mdc-typography--subtitle1">
                        <span>Report</span>
                      </div>
                    </div>
                    <div class="grid__cell" style="width: 20%">
                      <button style="width: 150px;"
                        class="mdc-button mdc-button--raised mdc-ripple-upgraded motion-button--small"
                        (click)="goToUserManagement()">Open</button>
                    </div>
                  </div>
                </div>
              </div> -->

            </ng-container>
          </div>
        </div>
      </div>
      <!--added back button-->
      <!-- <section class="grid__inner">
        <div class="grid__cell--span-all">
          <div class="grid__inner grid__inner--layout-b8b">
            <div class="grid__cell grid__cell--justify-center">
              <div class="mdc-btn-container">
                <div class="grid__cell text--align-center">
                  <a class="motion-button--cancel" tabindex="0" title="Back to calendar" (click)="previousPage()">Back
                    to
                    landing page</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
    </div>
  </article>
  <!-- <button (click)="claim()">Claim Api</button> -->
</div>