<h4 mat-dialog-title>Upload PDF</h4>
<mat-dialog-content>
  <form [formGroup]="uploadForm">
    <div class="file-upload-container">
      <div class="file-input-wrapper">
        <button mat-raised-button style="background-color:rgb(12, 12, 91); color: white;" type="button"
          (click)="fileInput.click()">
          Choose File
        </button>
        <input hidden type="file" #fileInput (change)="onFileSelected($event)" accept=".pdf">
        <span class="file-name" *ngIf="selectedFile">{{ selectedFile.name }}</span>
        <span class="file-name" *ngIf="!selectedFile">No file chosen</span>
      </div>

      <div class="form-group" *ngIf="selectedFile">
        <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
          <mat-label>Document Name</mat-label>
          <input matInput type="text" formControlName="docName" required>
          <mat-error *ngIf="uploadForm.get('docName').invalid && uploadForm.get('docName').touched">
            Document name is required.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="file-requirements">
        <p>Accepted file format: PDF</p>
        <p>Maximum file size: 10MB</p>
      </div>
    </div>
  </form>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button
    [ngClass]="{ 'mdc-button--raised':  uploadForm.valid, 'mdc-ripple-upgraded': uploadForm.valid, 'mdc-button__fill--red':  uploadForm.valid, 'mdc-button': uploadForm.valid, 'mat-raised-button': !uploadForm.valid }"
    [disabled]="!uploadForm.valid" (click)="Submit()">Upload</button>
</mat-dialog-actions>