import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalDataService } from './global-data.service';
import { Observable, catchError, of, tap } from 'rxjs';
import * as Enums from '../../shared/enums';

@Injectable({
  providedIn: 'root'
})
export class RiskService {

  httpOptions: any = {};

  constructor(public http: HttpClient,
    private globalServ: GlobalDataService) { }



  getAllReviewIds(rsaidnumber, consultationId): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();

    return this.http.get(`${Enums.API_PATH}${Enums.API_PATHS.GET_ALL_REVIEW_IDS}${rsaidnumber}/${consultationId}`, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get hra review ids', undefined)));
  }

  getHRAResponseByReviewId(id: string): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();

    return this.http.get(`${Enums.API_PATH}${Enums.API_PATHS.GET_HRA_RESPONSE_BY_REVIEWID}${id}`, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get hra review ids', undefined)));
  }

  getHIVTVResponseByReviewId(obj: any): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(`${Enums.API_PATH}${Enums.API_PATHS.GET_HIV_TV_RESPONSE_BY_REVIEWID}`, obj, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get hiv tb review ids', undefined)));
  }

  getCancerResponseByReviewId(obj: any): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(`${Enums.API_PATH}${Enums.API_PATHS.GET_CANCER_RESPONSE_BY_REVIEWID}`, obj, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get hiv tb review ids', undefined)));
  }
  getPsychosocialResponseByReviewId(obj: any): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(`${Enums.API_PATH}${Enums.API_PATHS.GET_PSYCHOSOCIAL_RESPONSE_BY_REVIEWID}`, obj, this.httpOptions).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get hiv tb review ids', undefined)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }

}
