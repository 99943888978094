import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsService } from 'src/app/shared/services/claims.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-view-nonmembers',
  templateUrl: './view-nonmembers.component.html',
  styleUrls: ['./view-nonmembers.component.scss']
})
export class ViewNonmembersComponent implements OnInit {
  @Input() companyClaims: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading: boolean = false;
  pagesize = 0;
  rejectedClaimsData: any = [];
  displayedColumns: string[] = ['patientRefNumber', 'firstName', 'surname', 'reason', 'action'];
  dataSource: MatTableDataSource<any>;

  constructor(private claimService: ClaimsService, private router: Router, private route: ActivatedRoute, private globalData: GlobalDataService, private dialog: MatDialog) {
    this.globalData.updateBackRoute('/wellness-event/corporate/non-members');
  }

  ngOnInit(): void {
    this.getNonMembersByCompany();
  }

  async getNonMembersByCompany() {
    this.isLoading = true;
    if (history?.state) {
      await this.claimService.getNonmembersforCompany(history.state['object'].id).then(data => {
        this.rejectedClaimsData = data.map(claim => ({
          patientRefNumber: claim.cr.patientRefNumber,
          firstName: claim.cr.patientFirstName,
          surname: claim.cr.patientSurname,
          reason: claim.cr?.reason,
          data: claim.cr
        }));
        this.dataSource = new MatTableDataSource(this.rejectedClaimsData);
        this.dataSource.paginator = this.paginator;
        this.pagesize = this.rejectedClaimsData.length;
        this.isLoading = false;
      }).catch(error => {
        console.log("Error fetching the Members for rejected claims", error);
        this.isLoading = false;
      });
    }
  }

  moveToRejectedClaims(object: any) {
    let id = object.id;
    this.isLoading = true;
    this.claimService.UpdateNonMember(id).then(data => {
      this.getNonMembersByCompany();
    }).catch(err => {
      console.log("error", err);
      this.isLoading = false;
    })
  }

}
