import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { SupportService } from 'src/app/shared/services/support.service';
import { WellnessReportService } from 'src/app/shared/services/wellness-report.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'reschedule-event',
  templateUrl: './reschedule-event.component.html',
  styleUrls: ['./reschedule-event.component.scss']
})
export class RescheduleEventComponent implements OnInit {

  isLoading: boolean = false;
  companyNames: any;
  form: FormGroup;
  selectedCompanyNames: any;
  maxDate: Date;
  startDate: Date;
  username: string;
  excludeEvents = ["377d722b-7400-4160-98e8-f7084ce2a7e6", "120ad178-d3c5-4361-9547-484d8e6b3a35"];

  constructor(private globalData: GlobalDataService,
    private eventNames: WellnessReportService,
    private fb: FormBuilder,
    private supportService: SupportService
  ) {
    this.globalData.updateBackRoute('/support-landing');
    this.globalData.userFullNameVal.subscribe(val => this.username = val);
  }

  ngOnInit(): void {
    // Set startDate to today's date
    this.startDate = new Date();

    // Set maxDate to 24 from today's date
    this.maxDate = new Date();
    this.maxDate.setDate(this.startDate.getDate() + 23);

    this.createForm();
    this.GetListOfEvents();
  }

  createForm() {
    this.form = this.fb.group({
      companyId: [null, Validators.required],
      eventDate: [{ value: null, disabled: true }, Validators.required],
      date: [null, Validators.required],
      reason: [null, Validators.required],
      confirmation: [null, Validators.required]
    })
  }

  GetListOfEvents() {

    this.isLoading = true;
    this.eventNames.getEventNames().subscribe((data) => {
      data = data.filter(x => !this.excludeEvents.includes(x.eventStatusId))
      this.companyNames = data.sort((a, b) => a.description.localeCompare(b.description));
      this.isLoading = false;
    });
  }

  selectOption(event: any) {
    this.selectedCompanyNames = event.value.id;
    this.form.get('eventDate').setValue(event.value.eventDate);
  }

  rescheduleEvent() {
    if (this.form.valid) {
      let data = this.form.getRawValue();
      let obj = {
        eventIds: data.companyId.id,
        date: data.date,
        username: this.username,
        reason: data.reason,
        confirmation: data.confirmation
      }
      this.isLoading = true;
      this.supportService.rescheduleEvent(obj).subscribe(
        (res) => {
          console.log(res);
          this.isLoading = false;
          this.form.reset();
          Swal.fire({
            title: 'Reschedule Event',
            text: 'Event is rescheduled successfully',
            icon: 'success',
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          }).then((result) => {
            this.GetListOfEvents();
          })
        }
      ), (
          (error) => {
            console.log(error);
            this.isLoading = false;
            Swal.fire({
              title: 'Reschedule Event',
              text: 'Error while rescheduling the event',
              icon: 'error',
              showConfirmButton: false,
              timer: 3500,
              timerProgressBar: true
            })
          }
        )
    }
  }

}
