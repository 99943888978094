<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>

  <article class="grid">
    <mat-card class="password-reset-card">
      <mat-card-header>
        <mat-card-title>Reset Password</mat-card-title>
        <mat-card-subtitle>Enter user's email to reset password</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label style="margin-left: 2%;">Email</mat-label>
            <input style="margin-left: 2%;" matInput formControlName="email" placeholder="Enter user's email"
              type="email">

            <mat-error *ngIf="f.email.errors?.required">Email is required</mat-error>
            <mat-error *ngIf="f.email.errors?.email">Please enter a valid email address</mat-error>
          </mat-form-field>

          <div class="button-container">
            <button
              [ngClass]="{ 'mdc-button--raised': resetForm.valid && resetForm.dirty, 'mdc-ripple-upgraded': resetForm.valid && resetForm.dirty, 'mdc-button__fill--darkblue': resetForm.valid && resetForm.dirty, 'mdc-button': resetForm.valid && resetForm.dirty, 'mat-raised-button': resetForm.invalid || resetForm.pristine }"
              [disabled]="resetForm.invalid || resetForm.pristine" id="saveButton"
              style="vertical-align: middle; margin-top: -2%;">Submit</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </article>
</div>