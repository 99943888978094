<article class="grid">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <section class="mdc-typography--body1">
    <div>
      <h1 class="mdc-typography--headline2 text--align-center">Reschedule Event</h1>
    </div>
  </section><br><br>
  <div class="grid__inner grid__inner--layout-b8b">
    <div class="grid__cell">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="mdc-list-item text--align-left">
              <strong>Reschedule details</strong>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <form [formGroup]="form">

            <div class="form-group">
              <mat-form-field class="whitneyFontClass motion-form-field unsetMarginPadding" appearance="legacy"
                required>
                <mat-label>Select the Event</mat-label>
                <mat-select formControlName="companyId" (selectionChange)="selectOption($event)" #EventSelect>
                  <mat-option *ngFor="let event of companyNames" [value]="event">{{event.description}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
                <mat-label>Event current date</mat-label>
                <input matInput formControlName="eventDate">
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding"
                required>
                <mat-label>Reschedule Event date</mat-label>
                <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="date" [min]="startDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="month"></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="form-group">
              <mat-form-field class="whitneyFontClass motion-form-field unsetMarginPadding" appearance="legacy">
                <mat-label>Reschedule reason</mat-label>
                <textarea matInput formControlName="reason" placeholder="Enter reschedule reason"></textarea>
              </mat-form-field>
            </div>

            <br />
            <div class="form-group">
              <mat-checkbox required formControlName="confirmation">I confirm that all information given is
                correct.</mat-checkbox>
              <mat-error *ngIf="form.get('confirmation').invalid && form.get('confirmation').touched">
                Reschedule event confirmation is required.
              </mat-error>
            </div>

            <br />
            <div id="saveContainer">
              <button mat-raised-button class="button event" [disabled]="form.invalid"
                (click)="rescheduleEvent()">Reschedule
                Event</button>
            </div>

          </form>

        </mat-expansion-panel>
      </mat-accordion>

      <!-- <div class="mainForm">
      <form [formGroup]="form">
        <div style="display: flex; align-items: center; gap: 55px;">

          <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker required>
            <mat-label>Select the Event</mat-label>
            <mat-select formControlName="companyId" (selectionChange)="selectOption($event)" #EventSelect>
              <mat-option *ngFor="let event of companyNames" [value]="event.id">{{event.description}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding" required>
            <mat-label>Reschedule Event date</mat-label>
            <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="date" [min]="startDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="month"></mat-datepicker>
          </mat-form-field>

          <button mat-raised-button class="button event" [disabled]="form.invalid"
            (click)="rescheduleEvent()">Reschedule
            Event</button>
        </div>
      </form>
    </div> -->
    </div>
  </div>
</article>