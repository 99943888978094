import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap } from 'rxjs';

import * as Enums from '../enums';
import { ClaimParams, ClaimResponse } from '../interfaces/claimParams';
import { GlobalDataService } from './global-data.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  httpOptions: any = {};

  parameter: ClaimParams = {
    MediscorPCNCode: '',
    ProviderBHFNumber: '',
    PayeeIndicator: '',
    PatientRefNumber: '',
    DateOfService: '',
    MemberNumber: '',
    DependentCode: '',
    DateOfBirth: '',
    PatientGender: '',
    PatientFirstName: '',
    PatientSurname: '',
    ScriptDate: '',
    PrimaryICD10Code: '',
    TotalVAT: '',
    GrossAmountDue: '',
    SubmittedCost: ''
  }
  constructor(private http: HttpClient, private globalServ: GlobalDataService) {

  }

  claim(params: ClaimParams, headers: HttpHeaders) {

    this.http.get(`https://mediscor-benefit-api.onrender.com/claims?MediscorPCNCode=MDS0041PW&ProviderBHFNumber=8800002&PayeeIndicator=P&PatientRefNumber=186609&DateOfService=2024-06-14&MemberNumber=919720989&DependantCode=001&DateOfBirth=2024-06-14&PatientGender=F&PatientFirstName=Nicole L&PatientSurname=Gilbert&ScriptDate=2024-06-14&PrimaryICD10Code=Z76.9&TotalVAT=000002054&GrossAmountDue=000015750`, { headers }).subscribe(data => {
      console.log("Ping - Claims Data", data);
    }, err => {
      console.log(err);
    }

    )
  }

  GetAllClaimByCompany(): Promise<any> {
    return this.http.get<any>(`${Enums.API_PATH}${Enums.API_PATHS.GET_CLAIM_COMPANY}`, this.httpOptions).toPromise().then(data => {
      console.log("Ping - Claims Data");
      return data;
    }).catch(err => {
      console.error('Error fetching claims data:', err);
      //throw err; // Rethrow to handle it later
    });
  }

  GetAllRejectedClaimByCompany(): Promise<any> {
    return this.http.get<any>(`${Enums.API_PATH}${Enums.API_PATHS.GET_REJECTED_CLAIM_COMPANY}`, this.httpOptions).toPromise().then(data => {
      console.log("Ping -Rejected Claims Data");
      return data;
    }).catch(err => {
      console.error('Error fetching Rejected claims data:', err);
      //throw err; // Rethrow to handle it later
    });
  }
  getAllNonMembers(): Promise<any> {
    return this.http.get<any>(`${Enums.API_PATH}${Enums.API_PATHS.GET_ALL_NON_MEMBERS}`, this.httpOptions).toPromise().then(data => {
      console.log("Ping - Get All Non Members for Companies");
      return data;
    }).catch(err => {
      console.error('Error fetching Non Members for Companies data:', err);
      //throw err; // Rethrow to handle it later
    });
  }

  getRejectedCLaimMembers(eventId: string): Promise<any> {
    return this.http.get<any>(`${Enums.API_PATH}${Enums.API_PATHS.GET_REJECTED_CLAIM_COMPANY_BY_ID}` + eventId, this.httpOptions).toPromise().then(data => {
      console.log("Ping -Rejected Claims Data");
      return data;
    }).catch(err => {
      console.error('Error fetching Rejected claims data:', err);
      //throw err; // Rethrow to handle it later
    });
  }
  getNonmembersforCompany(eventId: string): Promise<any> {
    return this.http.get<any>(`${Enums.API_PATH}${Enums.API_PATHS.GET_ALL_NON_MEMBERS_BY_COMPANY}` + eventId, this.httpOptions).toPromise().then(data => {
      console.log("Ping - All nonmembers for specific company Data");
      return data;
    }).catch(err => {
      console.error('Error fetching nonmembers for specific company  data:', err);
      //throw err; // Rethrow to handle it later
    });
  }

  GetAllCompanyName(): Promise<any> {
    return this.http.get<any>(`${Enums.API_PATH}${Enums.API_PATHS.GET_ALL_EVENTS_NAME}`, this.httpOptions).toPromise().then(data => {
      console.log("Ping - Claims company name");
      return data;
    }).catch(err => {
      console.error('Error fetching claims company data:', err);
      //throw err; // Rethrow to handle it later
    });
  }

  InsertIntoClaimResponse(data: any, id: any): Promise<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    return this.http.post<any>(`${Enums.API_PATH}${Enums.API_PATHS.INSERT_INTO_CLAIMRESPONSE}`, { body: data, consultationId: id }, { headers }).toPromise().then(data => {
      console.log("Inserted successfully into claim response");
      //return data;
    }).catch(err => {
      console.error('Error while inserting into claim response', err);
      //throw err; // Rethrow to handle it later
    });
  }

  UpdateIntoClaimResponse(obj: ClaimResponse): Promise<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });

    // Send the object directly without wrapping it in another object
    return this.http.post<any>(
      `${Enums.API_PATH}${Enums.API_PATHS.UPDATE_INTO_CLAIMRESPONSE}`,
      obj,  // Remove the { obj } wrapper
      { headers }
    ).toPromise().then(data => {
      console.log("Updated successfully into claim response");
      return data; // Return the response data
    }).catch(err => {
      console.error('Error while updating into claim response', err);
      //throw err; // Rethrow the error for proper error handling
    });
  }

  UpdateNonMember(id: string): Promise<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    return this.http.get<any>(`${Enums.API_PATH}${Enums.API_PATHS.UPDATE_NON_MEMBER_CLAIM + id}`, { headers }).toPromise().then(data => {
      console.log("updated successfully into claim response");
      //return data;
    }).catch(err => {
      console.error('Error while updating into claim response', err);
      //throw err; // Rethrow to handle it later
    });
  }

  processClaim(id: any, userId: any): Promise<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    return this.http.post<any>(`${Enums.API_PATH}${Enums.API_PATHS.PROCESS_CLAIM}${id}/${userId}`, { headers }).toPromise().then(data => {
      console.log("Processed claims successfully");
      //return data;
    }).catch(err => {
      console.error('Error while processing claims', err);
      //throw err; // Rethrow to handle it later
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }


}
