import { CommonModule, DatePipe } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatBadgeModule } from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatNativeDateModule,
  MatOptionModule
} from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule
} from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSliderModule } from '@angular/material/slider'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { FullCalendarModule } from '@fullcalendar/angular'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { AgGridModule } from 'ag-grid-angular'
import { GaugeChartModule } from 'angular-gauge-chart';
import { SignaturePadModule } from 'angular2-signaturepad'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'
import { IConfig, NgxMaskModule } from 'ngx-mask'
import { environment } from 'src/environments/environment'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { SurveyCompletedComponent } from './components/adhoc/survey-completed/survey-completed.component'
import { SurveyComponent } from './components/adhoc/survey.component'
import { EapAdditionalComponent } from './components/eap-portal/eap-additional/eap-additional.component';
import { EapLandingComponent } from './components/eap-portal/eap-landing/eap-landing.component';
import { EapaComponent } from './components/eapa/eapa.component';
import { RegistrationComponent } from './components/eapa/registration/registration.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component'
import { LoginErrorComponent } from './components/login-error/login-error.component';
import { ClientProfileDetailComponent } from './components/oh/client-profile-detail/client-profile-detail.component'
import { ClientProfileComponent } from './components/oh/client-profile/client-profile.component'
import { CloseOutComponent } from './components/oh/close-out/close-out.component'
import { COFComponent } from './components/oh/cof/cof.component'
import { OHConsentComponent } from './components/oh/consent/consent.component'
import { OHConsultationSummaryComponent } from './components/oh/consultation-summary/consultation-summary.component'
import { EAPCloseOutComponent } from './components/oh/eap-close-out/eap-close-out.component'
import { EAPIntakeComponent } from './components/oh/eap-intake/eap-intake.component'
import { EAPComponent } from './components/oh/eap/eap.component'
import { FileReviewComponent } from './components/oh/file-review/file-review.component'
import { FilteredAppointmentComponent } from './components/oh/filtered-appointment/filtered-appointment.component'
import { FinalCheckTestComponent } from './components/oh/final-check-test/final-check-test.component'
import { FinalCheckComponent } from './components/oh/final-check/final-check.component'
import { OHLandingPageComponent } from './components/oh/landing-page/landing-page.component'
import { NurseInterventionComponent } from './components/oh/nurse-intervention/nurse-intervention.component'
import { ReviewQuestionnaireComponent } from './components/oh/review-questionnaire/review-questionnaire.component'
import { ReviewVaccineComponent } from './components/oh/review-vaccine/review-vaccine.component'
import { OHReviewComponent } from './components/oh/review/review.component'
import { StepperOverviewComponent } from './components/oh/stepper-overview/stepper-overview.component'
import { VaccinesComponent } from './components/oh/vaccines/vaccines.component'
import { PreviewComponent } from './components/preview/preview.component'
import { QComponent } from './components/q/q.component'
import { ReportingComponent } from './components/reporting/reporting.component'
import { SatisfactionSurveyComponent } from './components/satisfaction-survey/satisfaction-survey.component';
import { SPAccessManagementComponent } from './components/service-provider/access-management/access-management.component'
import { AssignEventsComponent } from './components/service-provider/assign-events/assign-events.component';
import { CreateAdminAssistantComponent } from './components/service-provider/create-admin-assistant/create-admin-assistant.component'
import { CreateHealthPractitionerComponent } from './components/service-provider/create-health-practitioner/create-health-practitioner.component'
import { SPLandingPageComponent } from './components/service-provider/landing-page.component/landing-page.component'
import { ChangeConsentComponent } from './components/support/change-consent/change-consent.component';
import { MoveEventComponent } from './components/support/move-event/move-event.component';
import { PasswordResetComponent } from './components/support/password-reset/password-reset.component'
import { SupportLandingComponent } from './components/support/support-landing/support-landing.component';
import { UserSupportComponent } from './components/support/user-support/user-support.component';
import { AdhocWellnessBookingComponent } from './components/wellness-adhoc/booking/booking.component'
import { AdhocWellnessConsentComponent } from './components/wellness-adhoc/consent/consent.component'
import { AdhocWellnessConsultationSummaryComponent } from './components/wellness-adhoc/consultation-summary/consultation-summary.component'
import { AdhocWellnessHIVConsentComponent } from './components/wellness-adhoc/hiv-consent/hiv-consent.component'
import { AdhocWellnessLandingPageComponent } from './components/wellness-adhoc/landing-page/landing-page.component'
import { AdhocWellnessRegistrationComponent } from './components/wellness-adhoc/registration/registration.component'
import { AdhocWDEEventSummaryComponent } from './components/wellness-adhoc/reports/wde-event-summary/wde-event-summary.component'
import { AdhocWellnessReviewComponent } from './components/wellness-adhoc/review/review.component'
import { TransactionalReportComponent } from './components/wellness-adhoc/transactional-report/transactional-report.component';
import { CorporateWellnessBookingComponent } from './components/wellness-corporate/booking/booking.component'
import { CorporateWellnessConsentComponent } from './components/wellness-corporate/consent/consent.component'
import { CorporateWellnessConsultationSummaryComponent } from './components/wellness-corporate/consultation-summary/consultation-summary.component'
import { ClaimByCompanyComponent } from './components/wellness-corporate/financial/claim-by-company/claim-by-company.component';
import { ClaimLandingComponent } from './components/wellness-corporate/financial/claim-landing/claim-landing.component';
import { ClaimsReportComponent } from './components/wellness-corporate/financial/claims-report/claims-report.component'
import { FinancialComponent } from './components/wellness-corporate/financial/financial.component'
import { NonmembersComponent } from './components/wellness-corporate/financial/invoicing/nonmembers/nonmembers.component';
import { ViewNonmembersComponent } from './components/wellness-corporate/financial/invoicing/view-nonmembers/view-nonmembers.component';
import { FixClaimComponent } from './components/wellness-corporate/financial/re-claim/fix-claim/fix-claim.component';
import { EditMemberDailogComponent } from './components/wellness-corporate/financial/re-claim/view-members/edit-member-dailog/edit-member-dailog.component';
import { ViewMembersComponent } from './components/wellness-corporate/financial/re-claim/view-members/view-members.component';
import { CorporateWellnessFLUConsentComponent } from './components/wellness-corporate/flu-consent/flu-consent.component';
import { CorporateWellnessHIVConsentComponent } from './components/wellness-corporate/hiv-consent/hiv-consent.component'
import { CorporateWellnessLandingPageComponent } from './components/wellness-corporate/landing-page/landing-page.component'
import { CorporateWellnessPAPSmearConsentComponent } from './components/wellness-corporate/pap-smear/pap-smear.component';
import { CorporateWellnessPSAConsentComponent } from './components/wellness-corporate/psa-consent/psa-consent.component'
import { CorporateWellnessRegistrationComponent } from './components/wellness-corporate/registration/registration.component'
import { CorporateWDEEventSummaryComponent } from './components/wellness-corporate/reports/wde-event-summary/wde-event-summary.component'
import { CorporateWellnessReviewComponent } from './components/wellness-corporate/review/review.component'
import { RiskSummaryComponent } from './components/wellness-corporate/risk-summary/risk-summary.component';
import { TransationalReportComponent } from './components/wellness-corporate/transational-report/transational-report.component'
import { WellnessBookingComponent } from './components/wellness/booking/booking.component'
import { WellnessConsentComponent } from './components/wellness/consent/consent.component'
import { WellnessConsultationSummaryComponent } from './components/wellness/consultation-summary/consultation-summary.component'
import { ExecutionReportComponent } from './components/wellness/execution-report/execution-report.component'
import { WellnessHIVConsentComponent } from './components/wellness/hiv-consent/hiv-consent.component'
import { WellnessLandingPageComponent } from './components/wellness/landing-page/landing-page.component'
import { WellnessRegistrationComponent } from './components/wellness/registration/registration.component'
import { WDEEventSummaryComponent } from './components/wellness/reports/wde-event-summary/wde-event-summary.component'
import { WellnessReviewComponent } from './components/wellness/review/review.component'
import { AppDateAdapter } from './shared/adapters/app-date-adapter'
import { AdhocEventActivityComponent } from './shared/components/adhoc-event-activity/adhoc-event-activity.component'
import { AdhocWellnessInfoHeaderComponent } from './shared/components/adhoc-wellness-info-header/adhoc-wellness-info-header.component'
import { BackButtonComponent } from './shared/components/back-button/back-button.component'
import { BookingCalendarButtonComponent } from './shared/components/booking-calendar-button/booking-calendar-button.component'
import { CancellationDialogComponent } from './shared/components/cancellation-dialog/cancellation-dialog.component'
import { ConsentBaseComponent } from './shared/components/consent-base/consent-base.component'
import { CorporateWellnessInfoHeaderComponent } from './shared/components/corporate-wellness-info-header/corporate-wellness-info-header.component'
import { EventActivityComponent } from './shared/components/event-activity/event-activity.component'
import { EventStatusSelectorComponent } from './shared/components/event-status-selector/event-status-selector.component'
import { InfoBlockComponent } from './shared/components/info-block/info-block.component'
import { InfoHeaderEAPComponent } from './shared/components/info-header-eap/info-header-eap.component'
import { InfoHeaderComponent } from './shared/components/info-header/info-header.component'
import { OnlineIconComponent } from './shared/components/online-icon/online-icon.component'
import { PopupComponent } from './shared/components/popup/popup.component'
import { ProfileMenuComponent } from './shared/components/profile-menu/profile-menu.component'
import { QPreviewComponent } from './shared/components/q-preview/q-preview.component'
import { ReferralComponent } from './shared/components/referral/referral.component'
import { SignatureFieldComponent } from './shared/components/signature-field/signature-field.component'
import { TestNoteComponent } from './shared/components/test-note/test-note.component'
import { ThreeBarLoaderComponent } from './shared/components/three-bar-loader/three-bar-loader.component'
import { ThreeBarScreenComponent } from './shared/components/three-bar-screen/three-bar-screen.component'
import { ForgotPassowrdComponent } from './shared/components/user/forgot-passowrd/forgot-passowrd.component'
import { LoginComponent } from './shared/components/user/login/login.component'
import { SignupComponent } from './shared/components/user/signup/signup.component'
import { WellnessEventHeaderComponent } from './shared/components/wellness-event-header/wellness-event-header.component'
import { WellnessInfoHeaderComponent } from './shared/components/wellness-info-header/wellness-info-header.component'
import { CustomNumberFormatDirective } from './shared/directives/custom-number-format.directive'
import { PhoneNumberFormatDirective } from './shared/directives/phone-number-format.directive'
import { APP_DATE_FORMATS } from './shared/enums'
import { NewlinePipe } from './shared/pipes/newline.pipe';
import { NumberFormatPipe } from './shared/pipes/number.pipe';
import { RescheduleEventComponent } from './components/support/reschedule-event/reschedule-event.component';
import { ReportsLandingComponent } from './components/wellness-corporate/reports/reports-landing/reports-landing.component';
import { CaptureAnalysisComponent } from './components/wellness-corporate/reports/capture-analysis/capture-analysis.component';
import { FileUploadDialogComponent } from './shared/components/file-upload-dialog/file-upload-dialog.component';

//TODO: Register new token service + component here.






































































function initializeKeycloak(keycloak: KeycloakService) {
  var redirectPath = ''

  if (window.location.origin.indexOf('localhost') > 0) {
    redirectPath = window.location.origin + '/wellness-event/corporate/landing'
  } else {
    redirectPath = window.location.origin + '/perigon/#/wellness-event/corporate/landing'
  }

  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        //url: environment.keycloakUrl,
        realm: environment.keycloakRealm,
        //realm: environment.keycloakRealm,
        //        clientId: 'perigon',
        clientId: 'Perigon',
      },
      initOptions: {
        // comment out the following as silent checks are no longer allowed in Chrome
        //onLoad: 'check-sso',
        //silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        checkLoginIframe: false,
        redirectUri: redirectPath,
      },
    })
  // check-sso: With this feature enabled, the browser will not do a full redirect to the Keycloak server and back to our application,
  // instead this action will be performed in a hidden iframe, so the application resources only need to be loaded and parsed once
  // by the browser when the app is initialized and not again after the redirect back from Keycloak to the app.
}

/*function initialize(auth: AuthService) {
  var redirectPath = ''

  if (window.location.origin.indexOf('localhost') > 0) {
    let redirectPath = window.location.origin + '/landing'
  } else {
    let redirectPath = window.location.origin + '/perigon/#/oh-landing'
  }

  return () => Promise.resolve(true)
  // check-sso: With this feature enabled, the browser will not do a full redirect to the Keycloak server and back to our application,
  // instead this action will be performed in a hidden iframe, so the application resources only need to be loaded and parsed once
  // by the browser when the app is initialized and not again after the redirect back from Keycloak to the app.
}*/

const options: Partial<IConfig> = {
  validation: false,
  // other options
};
const components: any = null;
@NgModule({
  declarations: [
    AppComponent,
    EventActivityComponent,
    InfoHeaderComponent,
    EAPComponent,
    EAPIntakeComponent,
    EAPCloseOutComponent,
    SPAccessManagementComponent,
    InfoHeaderEAPComponent,
    WellnessEventHeaderComponent,
    CreateAdminAssistantComponent,
    StepperOverviewComponent,
    ConsentBaseComponent,
    NurseInterventionComponent,
    WellnessHIVConsentComponent,
    WellnessConsultationSummaryComponent,
    WellnessConsentComponent,
    WellnessReviewComponent,
    WellnessInfoHeaderComponent,
    AdhocWellnessInfoHeaderComponent,
    CorporateWellnessInfoHeaderComponent,
    LandingPageComponent,
    OHLandingPageComponent,
    AdhocWellnessBookingComponent,
    AdhocWellnessConsentComponent,
    AdhocWellnessConsultationSummaryComponent,
    AdhocWellnessHIVConsentComponent,
    AdhocWellnessLandingPageComponent,
    AdhocWellnessRegistrationComponent,
    AdhocWellnessReviewComponent,
    AdhocWDEEventSummaryComponent,
    OHConsultationSummaryComponent,
    OHConsentComponent,
    OHReviewComponent,
    ThreeBarLoaderComponent,
    ThreeBarScreenComponent,
    FilteredAppointmentComponent,
    FinalCheckComponent,
    CloseOutComponent,
    ReportingComponent,
    BookingCalendarButtonComponent,
    ClientProfileComponent,
    ReviewQuestionnaireComponent,
    FinalCheckTestComponent,
    ReviewVaccineComponent,
    COFComponent,
    ReferralComponent,
    ClientProfileDetailComponent,
    InfoBlockComponent,
    VaccinesComponent,
    SignatureFieldComponent,
    TestNoteComponent,
    BackButtonComponent,
    FileReviewComponent,
    PreviewComponent,
    QPreviewComponent,
    QComponent,
    SurveyComponent,
    WDEEventSummaryComponent,
    SurveyCompletedComponent,
    WellnessRegistrationComponent,
    WellnessBookingComponent,
    WellnessLandingPageComponent,
    PopupComponent,
    PhoneNumberFormatDirective,
    CustomNumberFormatDirective,
    SignupComponent,
    LoginComponent,
    ForgotPassowrdComponent,
    CreateHealthPractitionerComponent,
    SPLandingPageComponent,
    CancellationDialogComponent,
    ExecutionReportComponent,
    ProfileMenuComponent,
    OnlineIconComponent,
    EventStatusSelectorComponent,
    AdhocEventActivityComponent,
    CorporateWellnessBookingComponent,
    CorporateWellnessConsentComponent,
    CorporateWellnessConsultationSummaryComponent,
    CorporateWellnessHIVConsentComponent,
    CorporateWellnessPSAConsentComponent,
    CorporateWellnessFLUConsentComponent,
    CorporateWellnessLandingPageComponent,
    CorporateWellnessRegistrationComponent,
    CorporateWellnessReviewComponent,
    CorporateWDEEventSummaryComponent,
    FinancialComponent,
    TransationalReportComponent,
    EapaComponent,
    RegistrationComponent,
    TransactionalReportComponent,
    CorporateWellnessPAPSmearConsentComponent,
    ClaimByCompanyComponent,
    ClaimLandingComponent,
    SupportLandingComponent,
    ChangeConsentComponent,
    MoveEventComponent,
    LoginErrorComponent,
    ClaimsReportComponent,
    AssignEventsComponent,
    FixClaimComponent,
    ViewMembersComponent,
    EditMemberDailogComponent,
    EapLandingComponent,
    EapAdditionalComponent,
    NonmembersComponent,
    ViewNonmembersComponent,
    RiskSummaryComponent,
    SatisfactionSurveyComponent,
    UserSupportComponent,
    PasswordResetComponent,
    RescheduleEventComponent,
    ReportsLandingComponent,
    CaptureAnalysisComponent,
    FileUploadDialogComponent
  ],
  imports: [
    BrowserModule,
    MatTabsModule,
    MatSnackBarModule,
    MatMenuModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    HttpClientModule,
    MatDialogModule,
    NgbModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatOptionModule,
    MatStepperModule,
    MatSelectModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatExpansionModule,
    KeycloakAngularModule,
    // @ts-ignore
    SignaturePadModule,
    FullCalendarModule,
    // @ts-ignore
    AgGridModule.forRoot(components),
    // @ts-ignore
    NgxMaskModule.forRoot(options),
    MatBadgeModule, // Add this line to import MatBadgeModule
    GaugeChartModule,
    MatCardModule
  ],
  exports: [MatBadgeModule],
  providers: [
    MatDatepickerModule,
    DatePipe,
    NumberFormatPipe,
    NewlinePipe,

    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: APP_INITIALIZER, useFactory: initializeKeycloak, multi: true, deps: [KeycloakService] },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
