<div class="container">
  <article class="grid">
    <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
    <three-bar-loader *ngIf="isLoading"></three-bar-loader>
    <div>
      <section class="mdc-typography--body1">
        <h1 class="mdc-typography--headline2 text--align-center">Risk summary</h1>
      </section>
      <div class="grid__inner grid__inner--layout-b8b">
        <div class="grid__cell">
          <span class="blank-line blank-line-2 d-block"></span>
          <app-corporate-wellness-info-header></app-corporate-wellness-info-header>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="border" (click)="showDetails('CVR','G')">
        <h6>Cardio Vascular Risk</h6>
        <rg-gauge-chart *ngIf="isCVRData" [canvasWidth]="cvrcanvasWidth" [needleValue]="cvrneedleValue"
          [centralLabel]="cvrcentralLabel" [options]="cvroptions"></rg-gauge-chart>
        <p *ngIf="!isCVRData">No data available</p>
      </div>
      <div class="border" (click)="showDetails('HIVTB','G')">
        <h6>HIV/TB Risk</h6>
        <rg-gauge-chart *ngIf="isHIVTBData" [canvasWidth]="cvrcanvasWidth" [needleValue]="hivTBneedleValue"
          [centralLabel]="cvrcentralLabel" [options]="hivTboptions"></rg-gauge-chart>
        <p *ngIf="!isHIVTBData">No data available</p>
      </div>
      <div class="border" (click)="showDetails('CANCER','G')">
        <h6>Cancer Risk</h6>
        <rg-gauge-chart *ngIf="isCancerData" [canvasWidth]="cvrcanvasWidth" [needleValue]="cancerneedleValue"
          [centralLabel]="cvrcentralLabel" [options]="canceroptions"></rg-gauge-chart>
        <p *ngIf="!isCancerData">No data available</p>
      </div>
      <div class="border" (click)="showDetails('PSYCO','G')">
        <h6>Psychosocial Risk</h6>
        <rg-gauge-chart *ngIf="isPsychosocialData" [canvasWidth]="cvrcanvasWidth"
          [needleValue]="psychosocialneedleValue" [centralLabel]="cvrcentralLabel"
          [options]="psychosocialoptions"></rg-gauge-chart>
        <p *ngIf="!isPsychosocialData">No data available</p>
      </div>
    </div>
    <div class="cvrMain" style="justify-items: center;;">
      <div class="row detailsDrop" *ngIf="isDropdown" style="display: flex;">
        <div class="drop">
          <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
            <mat-label>Select Year</mat-label>
            <mat-select [(ngModel)]="selectedYear" (selectionChange)="showDetails('CVR','D')">
              <mat-option *ngFor="let li of years" [value]="li">{{li}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="btn grid__cell--span-3-desktop grid__cell--span-all">
            <button class="mdc-button mdc-button--outlined mdc-ripple-upgraded motion-button--short">
              Get Details
            </button>
          </div> -->
      </div>
      <div class="tableData" *ngIf="isCVRDetails">
        <h4>Cardio Vascular Risk Factor</h4>
        <div *ngIf="cvrMapperData">
          <!-- <ul>
          <li *ngFor="let key of Object.keys(cvrMapperData)">
            {{ key }}: {{ cvrMapperData[key] }}
          </li>
        </ul> -->
          <table border="1" style="width: 100%;">
            <tr *ngFor="let item of cvrMapperData">
              <td class="text-left">{{ item.label }}</td>
              <td class="text-left" [ngStyle]="{ 'color': item.color }">{{ item.value==null?item.response:item.value
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div class="mainHIVTB">
      <div class="HIVTBMain" style="justify-items: center;;">
        <div class="row detailsDrop" *ngIf="isHivTBDropdown" style="display: flex;">
          <div class="drop">
            <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
              <mat-label>Select Year</mat-label>
              <mat-select [(ngModel)]="selectedYear" (selectionChange)="showDetails('HIVTB','D')">
                <mat-option *ngFor="let li of years" [value]="li">{{li}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="tableData" *ngIf="isHIVTBTable">
          <h4>HIV/TB Risk Factor</h4>
          <div *ngIf="hivTBMapperData">
            <table border="1" style="width: 100%;">
              <tr *ngFor="let item of hivTBMapperData">
                <td class="text-left">{{ item.label }}</td>
                <td class="text-left" [ngStyle]="{ 'color': item.color }">{{ item.value==null?item.response:item.value
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div class="mainCancer">
      <div class="CancerMain" style="justify-items: center;;">
        <div class="row detailsDrop" *ngIf="isCancerDropdown" style="display: flex;">
          <div class="drop">
            <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
              <mat-label>Select Year</mat-label>
              <mat-select [(ngModel)]="selectedYear" (selectionChange)="showDetails('CANCER','D')">
                <mat-option *ngFor="let li of years" [value]="li">{{li}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="tableData" *ngIf="isCancerTable">
          <h4>Cancer Risk Factor</h4>
          <div *ngIf="cancerMapperData">
            <table border="1" style="width: 100%;">
              <tr *ngFor="let item of cancerMapperData">
                <td class="text-left">{{ item.label }}</td>
                <td class="text-left" [ngStyle]="{ 'color': item.color }">{{ item.value==null?item.response:item.value
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="mainPshyco">
      <div class="PshycoMain" style="justify-items: center;;">
        <div class="row detailsDrop" *ngIf="isPsychosocialDropdown" style="display: flex;">
          <div class="drop">
            <mat-form-field appearance="legacy" class="whitneyFontClass motion-form-field unsetMarginPadding">
              <mat-label>Select Year</mat-label>
              <mat-select [(ngModel)]="selectedYear" (selectionChange)="showDetails('CANCER','D')">
                <mat-option *ngFor="let li of years" [value]="li">{{li}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="tableData" *ngIf="isPsychosocialTable">
          <h4>Psychosocial Risk Factor</h4>
          <div *ngIf="psychosocialMapperData">
            <table border="1" style="width: 100%;">
              <tr *ngFor="let item of psychosocialMapperData">
                <td class="text-left">{{ item.label }}</td>
                <td class="text-left" [ngStyle]="{ 'color': item.color }">{{ item.value==null?item.response:item.value
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="mdc-btn-container">
      <div class="grid__cell text--align-center">
        <a class="motion-button--cancel" tabindex="0" title="Return to previous page" (click)="back()">Back</a>
      </div>
    </div>
  </article>
</div>