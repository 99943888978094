<div class="container">
  <span *ngIf="isLoading" class="blank-line blank-line-4 d-block"></span>
  <three-bar-loader *ngIf="isLoading"></three-bar-loader>
  <section class="mdc-typography--body1">
    <!-- <div style="position: absolute; left: 6%;" (click)="goToClaimLanding()">
      <a href="#" class="previous round"><i style="font-size:24px" class="fa">&#xf015;</i></a>

    </div> -->
    <div>
      <h1 class="mdc-typography--headline2 text--align-center">Non Members</h1>
    </div>
  </section><br><br>
  <article class="grid">
    <!-- <div style="display: flex;">
      <div class="grid__cell--span-3-desktop grid__cell--span-all" style="width: 50%; margin-right: 5%;">
        <mat-form-field class="whitneyFontClass motion-form-field" appearance="standard" hideRequiredMarker>
          <mat-label>Select the Event or Company</mat-label>
          <mat-select [(ngModel)]="selectedMemberNames" (selectionChange)="selectOption($event)" #EventSelect multiple>
            <mat-option *ngFor="let event of listOfMemberNames" [value]="event.id">{{event.description}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button class="claimProcess" (click)="searchClaim()">Search</button>
    </div> -->
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" class="w-full">

        <!-- ID Column -->
        <ng-container matColumnDef="patientRefNumber" width="25%">
          <th class="text-left" mat-header-cell *matHeaderCellDef> ID Number
          <th>
          <td class="text-left" mat-cell *matCellDef="let element"> {{element.patientRefNumber}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="firstName" width="40%">
          <th class="text-left" mat-header-cell *matHeaderCellDef> Name </th>
          <td class="text-left" mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>

        <!-- Surname Column -->
        <ng-container matColumnDef="surname" width="25%">
          <th class="text-left" mat-header-cell *matHeaderCellDef>Surname</th>
          <td class="text-left" mat-cell *matCellDef="let element"> {{element.surname}} </td>
        </ng-container>

        <!-- Reason Column -->
        <ng-container matColumnDef="reason" width="25%">
          <th class="text-left" mat-header-cell *matHeaderCellDef> Rejection reason </th>
          <td class="text-left" mat-cell *matCellDef="let element"> {{element.reason}} </td>
        </ng-container>

        <!-- Button Column -->
        <ng-container matColumnDef="action" width="10%">
          <th class="text-left" mat-header-cell *matHeaderCellDef></th>
          <td class="text-left" mat-cell *matCellDef="let element">
            <button mat-raised-button style="background-color: #ff403c; color: white;"
              (click)="moveToRejectedClaims(element.data)">Move to Rejected Claims</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="5" showFirstLastButtons
        aria-label="Select page of members">
      </mat-paginator>
    </div>

  </article>

</div>