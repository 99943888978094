import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { KeycloakrestapiService } from 'src/app/shared/services/keycloakrestapi.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  resetForm: FormGroup;
  submitted = false;
  isLoading: boolean = false;
  constructor(private formBuilder: FormBuilder, private snackBar: MatSnackBar, private globalData: GlobalDataService, private keycloak: KeycloakrestapiService, private router: Router) {
    this.globalData.updateBackRoute('/support-landing');
  }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get f() { return this.resetForm.controls; }

  async onSubmit() {
    this.isLoading = true;
    try {
      const res = await this.keycloak.resetPassword(this.resetForm.value.email);
      const msg = res.res === "Reset" ? "Password has been reset successfully and email sent to " + this.resetForm.value.email : res.res;
      Swal.fire({
        title: `${res.res === "Reset" ? "Success" : "Error"}`,
        html: msg,
        icon: `${res.res === "Reset" ? "success" : "warning"}`,
        iconColor: `${res.res === "Reset" ? "green" : "red"}`,
        confirmButtonText: 'Confirm',
        allowOutsideClick: () => {
          this.router.navigate(['/support-landing']);
          return false;
        },
        preConfirm: () => {
          this.router.navigate(['/support-landing']);
        }
      });
      this.isLoading = false;
      this.router.navigate(['/support-landing']);
    } catch (err) {
      Swal.fire({
        title: `Error`,
        html: `Could not reset password, please contact helpdesk with the following error: <b>${err.error?.message || err.message || 'Unknown error'}</b>`,
        icon: 'warning',
        iconColor: 'red',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        allowOutsideClick: () => {
          this.router.navigate(['/support-landing']);
          return false;
        },
        preConfirm: () => {
          this.router.navigate(['/support-landing']);
        }
      });
      this.isLoading = false;
    }
  }


}
