import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as Enums from '../enums';
import { GlobalDataService } from './global-data.service';
import { Observable, catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  httpOptions: any = {};
  constructor(private http: HttpClient, private globalServ: GlobalDataService) {
    // this.httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //   })
    // }
  }

  getAssessmentsPerformed(idNumber: string, eventId: string): Promise<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.get<any>(`${Enums.API_PATH + Enums.API_PATHS.GET_ASSESSMENTS_DONE + idNumber + "/" + eventId}`).toPromise().then(data => {
      console.log("Ping - Assessments performed", data);
      return data;
    }).catch(err => {
      console.error('Error fetching assessments performed data:', err);
      //throw err; // Rethrow to handle it later
    });
  }

  changeConsent(idNumber: string, eventId: string, result: string): Promise<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post<any>(
      `${Enums.API_PATH + Enums.API_PATHS.CHANGE_CONSENT + idNumber + "/" + eventId + "/" + result}`,
      {}, // Empty object as body
      this.httpOptions
    ).toPromise().then(data => {
      console.log("Ping - Change Consent", data);
      return data;
    }).catch(err => {
      console.error('Error fetching change consent data:', err);
    });
  }

  moveEvent(d: any): Promise<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post<any>(
      `${Enums.API_PATH + Enums.API_PATHS.MOVE_EVENT}`,
      { PatientRefNumber: d.idNumber, CurrentEvent: d.currentCompanyId, NewEvent: d.newCompanyId }, // Empty object as body
      this.httpOptions
    ).toPromise().then(data => {
      console.log("Ping - move event", data);
      return data;
    }).catch(err => {
      console.error('Error while moving the event', err);
    });
  }

  rescheduleEvent(obj): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    return this.http.post(`${Enums.API_PATH + Enums.API_PATHS.RESCHEDULE_EVENT}`, { EventIds: obj.eventIds, Date: obj.date, Username: obj.username, Reason: obj.reason, Confirmation: obj.confirmation }).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable to get users', undefined))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }

}

