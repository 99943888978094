import { relative } from 'path';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-user-support',
  templateUrl: './user-support.component.html',
  styleUrls: ['./user-support.component.scss']
})
export class UserSupportComponent implements OnInit {
  isLoading: boolean = false;
  isFailed: boolean = false;
  constructor(private router: Router, private globalData: GlobalDataService, private route: ActivatedRoute) {
    this.globalData.updateBackRoute('/support-landing');
  }

  ngOnInit(): void {
  }

  goToPwdRest() {
    this.router.navigate(['/support-landing/pwd-reset']);
  }

}
