import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';

import * as Enums from '../../shared/enums';
import { API_PATHS } from '../enums';
import { HeartScoreParams } from '../interfaces/heartscoreParams';
import { GlobalDataService } from './global-data.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};


@Injectable({
  providedIn: 'root'
})
export class HealthyHeartScoreService {
  httpOptions: any = {};
  bodyParams: HeartScoreParams = {
    clientDetails: {
      cellNumber: '',
      email: '',
    },
    providerDetails: {
      assessorName: '',
      location: ''
    },
    measurements: []
  };

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });
  response: number = null;


  multiplyAPIBody = {
    "clientDetails": {
      "cellNumber": "0836023202",
      "email": "hlavutelo.maluleke@mmiholdings.co.za"
    },
    "providerDetails": {
      "assessorName": "Ron Grobbelaar",
      "location": "Centurion"
    },
    "measurements": [
      {
        "name": "Blood Pressure",
        "type": "Health Risk Assessment",
        "date": "2024-06-10T11:13:35.520+02:00",
        "results": {
          "diastolic": "82",
          "systolic": "121"
        }
      },
      {
        "name": "Cholesterol",
        "type": "Health Risk Assessment",
        "date": "2024-06-10T11:13:35.520+02:00",
        "results": {
          "result": "4.5"
        }
      },
      {
        "name": "Glucose - Random",
        "type": "Health Risk Assessment",
        "date": "2024-06-10T11:13:35.520+02:00",
        "results": {
          "result": "8"
        }
      },
      {
        "name": "Height",
        "type": "Health Risk Assessment",
        "date": "2024-06-10T11:13:35.520+02:00",
        "results": {
          "result": "163"
        }
      },
      {
        "name": "Non-Smoking Declaration",
        "type": "Health Risk Assessment",
        "date": "2024-06-10T11:13:35.520+02:00",
        "results": {
          "result": "Non-Smoker"
        }
      },
      {
        "name": "Waist",
        "type": "Health Risk Assessment",
        "date": "2024-06-10T11:13:35.520+02:00",
        "results": {
          "result": "90"
        }
      },
      {
        "name": "Weight",
        "type": "Health Risk Assessment",
        "date": "2024-06-10T11:13:35.520+02:00",
        "results": {
          "result": "72"
        }
      },
      {
        "name": "Pregnant",
        "type": "Health Risk Assessment",
        "date": "2024-06-10T11:13:35.520+02:00",
        "results": {
          "result": "No"
        }
      }
    ]
  }

  constructor(private http: HttpClient, private globalServ: GlobalDataService) { }

  getMultiplyPDF(rsaidnumber: any, passportnumber: any, obj: any): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    let stringBody = JSON.stringify(this.bodyParams);
    return this.http.post(Enums.API_PATH + Enums.API_PATHS.GET_HEALTHY_HEART_SCORE, { body: stringBody, idNumber: rsaidnumber, passportNo: passportnumber, consultationId: obj.consultationId, patientId: obj.patientId, createdBy: obj.createdBy }, { headers }).pipe(
      tap(data => console.log("Ping")),
      catchError(this.handleError('Unable toget consultaiton detail', undefined)));
  }

  insertUpdateHHS(obj: any): Observable<any> {
    this.httpOptions = this.globalServ.getHttpOptions();
    let stringBody = JSON.stringify(this.bodyParams);
    obj.payload = stringBody;

    return this.http.post(Enums.API_PATH + Enums.API_PATHS.POST_INSERT_HHS, obj, {
      ...this.httpOptions,
      responseType: 'text'
    }).pipe(
      map(response => {
        console.log("Raw response:", response);
        try {
          return response;//JSON.parse(response);
        } catch (error) {
          console.log("Response is not JSON, returning as is");
          return response;
        }
      }),
      tap(data => console.log("Processed data:", data)),
      catchError(this.handleError('Unable to add consultation referral.', undefined))
    );
  }

  // multiply api seemingly expects the array of measurment objects to be in the order of multiplAPIBody
  orderMeasurements(): void {
    const order = [
      'Blood Pressure',
      'Cholesterol',
      'Glucose - Random',
      'Height',
      'Non-Smoking Declaration',
      'Waist',
      'Weight',
      'Pregnant'
    ];

    this.bodyParams.measurements.sort((a, b) => {
      return order.indexOf(a.name) - order.indexOf(b.name);
    });
  }

  heartScore(idNumber: string, passport: string): Observable<{ status: number, body: Blob }> {
    const url = API_PATHS.POST_HEALTHY_HEART_SCORE;

    const requestBody = {
      ...this.bodyParams,
      idNumber: idNumber,
      passportNo: passport
    }

    return this.http.post(url, JSON.stringify(requestBody), {
      headers: this.headers,
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      map(response => ({
        status: response.status,
        body: response.body
      })),
      tap(({ status, body }) => {
        this.response = status;
        console.log('Healthy Heart Score PDF received');

        // Create a Blob URL for the PDF
        const blobUrl = window.URL.createObjectURL(body);

        // Open the PDF in a new tab
        window.open(blobUrl, '_blank');

        // Create a temporary anchor element to trigger the download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'healthy_heart_score.pdf';
        link.click();

        // Clean up the Blob URL
        window.URL.revokeObjectURL(blobUrl);
      }),
      catchError(error => {
        console.error('Error fetching Healthy Heart Score:', error);
        return throwError('Error fetching Healthy Heart Score. Please try again later.');
      })
    );
  }


  Insert_Update_HHS(obj) {
    this.insertUpdateHHS(obj).subscribe(
      (res) => {
        console.log("Data saved successfully");
      },
      (err) => {
        console.log("Error while saving the data ", err);
      }
    )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`${message}`);
  }
}

// Function to convert a base64 encoded string to a Blob
export function base64ToBlob(base64, contentType) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

