import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-reports-landing',
  templateUrl: './reports-landing.component.html',
  styleUrls: ['./reports-landing.component.scss']
})
export class ReportsLandingComponent implements OnInit {
  isLoading: boolean = false;
  isFailed: boolean = false;
  constructor(private router: Router, private globalData: GlobalDataService) { }

  ngOnInit(): void {
    this.globalData.updateBackRoute('/wellness-event/corporate/landing');
  }

  goToTransactionalReport() {
    this.router.navigate(['/wellness-event/corporate/transactional-report']);
  }

  goToCaptureAnalysis() {
    this.router.navigate(['/wellness-event/corporate/capture-analysis']);
  }

}
